import { Disclosure } from '@headlessui/react';

export default function DisclosureComponent({
  main_btn,
  sub_buttons,
  main_btn_css,
  main_btn_open_css,
  disclosure_panel_style,
}) {
  return (
    <div className="w-full ">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={open ? main_btn_open_css : main_btn_css}
            >
              {main_btn}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className={`${open ? '' : 'rotate-180 transform'} h-5 w-5  `}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                />
              </svg>
            </Disclosure.Button>
            <Disclosure.Panel className={disclosure_panel_style}>
              {sub_buttons}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
