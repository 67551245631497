/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  changeOrderStatus,
  get_order_details,
  orderSelector,
} from '../../../store/feature/orderSlice';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import {
  ShipmentStatus,
  changeStatus,
  currentShipmentStatus,
  orderStatus,
} from '../../Shared/Constant/Status';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import OrderTimeline from '../OrderTimeline/OrderTimeline';

const ShipmentDetails = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const dispatch = useDispatch();

  // Status Changing
  const status_change = (status, order_details) => {
    if (status) {
      dispatch(
        changeOrderStatus({
          id: order_details.id,
          sendData: {
            order: {
              status: Number(status),
            },
          },
        })
      ).then((res) => {
        if (!res.payload.code) {
          setShowSuccess(true);
          setSuccess_message(
            Orders_text.orderdetail.status_changed_successfully
          );
          dispatch(
            get_order_details({
              id: order_details.id,
            })
          );
        }
      });
    }
  };

  const { isChangeStatusFetching } = useSelector(orderSelector);

  return (
    <div className="flex flex-col gap-6 ">
      {isChangeStatusFetching && <CustomLoading />}

      {order_details?.shipments?.map((shipment, index) => {
        const OrderDetails = shipment?.order_details;

        return (
          <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-card      p-4   sm:p-7   border-opacity-40  flex flex-col gap-4">
            <div className="flex items-center justify-between  gap-2  flex-wrap ">
              <p className="text-primary text-xl leading-6 font-medium  ">
                {Orders_text.orderdetail.shipment} {index + 1}
              </p>

              <p className="text-xl font-medium  ">{shipment?.title}</p>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:justify-between gap-4 sm:gap-16 ">
              <div className=" w-full flex-grow flex flex-col gap-4 ">
                {/* Order Details items  */}
                <div className="flex flex-col gap-2">
                  {/* Header */}
                  <div className=" grid  grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%] pb-2 border-b-2 border-gray-400     bg-white z-20">
                    <p className=" text-sm sm:text-lg text-black font-semibold ">
                      {Orders_text.orderdetail.items_summary}
                    </p>
                    <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.qty}
                    </p>
                    <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.price}
                    </p>
                    <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.total_price}
                    </p>
                  </div>
                  {/* Items */}
                  <div>
                    {OrderDetails?.map((item, index) => {
                      let listing_details = item?.listing;
                      let listing_variant_details = item?.variant
                        ? Object?.keys(item?.variant)?.length > 0
                          ? item?.variant
                          : undefined
                        : undefined;

                      let item_details = listing_variant_details
                        ? listing_variant_details
                        : listing_details;

                      return (
                        <Link
                          key={Math.random()}
                          href={
                            item?.listing?.slug
                              ? `/l/${item?.listing?.slug}`
                              : `/l/${
                                  item?.listing?.id
                                }-${item?.listing?.title.replace(
                                  /[ /?,#=]+/g,
                                  '-'
                                )}?id=true`
                          }
                        >
                          <a
                            className={[
                              '  grid  grid-cols-[50%,20%,30%]   md:grid-cols-[40%,20%,20%,20%] items-center py-3  cursor-pointer',
                              index !== OrderDetails.length - 1 &&
                                'border-b-2 border-gray-400 border-opacity-20',
                            ].join(' ')}
                          >
                            <div
                              className=" flex items-center ltr:pl-1 rtl:pr-1"
                              key={item.id}
                            >
                              {item_details.images?.length > 0 && (
                                <img
                                  src={getThumbnailImage(
                                    item_details.images[0]
                                  )}
                                  className="h-auto w-[50px] object-cover object-left-top"
                                  alt="image"
                                />
                              )}
                              <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                                <p className=" text-sm font-semibold  ">
                                  {item_details.title}
                                </p>
                                {item?.listing?.fulfilled_by_platform && (
                                  <img
                                    className=" w-auto h-auto max-w-[80px]"
                                    src={
                                      TYPE_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_platform_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                                {!item?.listing?.fulfilled_by_platform && (
                                  <img
                                    className="w-auto h-auto  max-w-[80px]"
                                    src={
                                      TYPE_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_seller_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                              </div>
                            </div>
                            <div>
                              <p className=" text-sm font-semibold  text-center">
                                X {item.quantity}
                              </p>
                            </div>
                            <div className=" hidden md:block">
                              <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                                {item.list_price.formatted}
                              </p>
                            </div>
                            <div>
                              <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                                {item.list_price.formatted}
                              </p>
                            </div>
                          </a>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Timeline */}
              {shipment?.shipment_status_history?.length > 0 &&
                order_details?.type !== 'digital' && (
                  <div className="w-full sm:w-auto flex-grow sm:flex-none  ">
                    <OrderTimeline
                      shipment={shipment}
                      order_details={order_details}
                    />
                  </div>
                )}
            </div>

            {/* Status change btn */}
            <div className="flex items-center justify-end gap-3 flex-wrap mt-2">
              <div className="flex items-center gap-2 ">
                {/* <p>{Orders_text.orderlist.order_status} :</p> */}
                <p className="    text-primary font-semibold">
                  {currentShipmentStatus(shipment.status)}
                </p>
              </div>
              {/* {order_details?.next_status.length > 0 && (
                <div className="flex items-center justify-end">
                  <button
                    className="bg-primary hover:bg-transparent border border-transparent hover:border-primary py-1 px-3  text-white  hover:text-primary rounded-card transition-all duration-300   "
                    onClick={() =>
                      status_change(
                        order_details?.next_status[0],
                        order_details
                      )
                    }
                  >
                    {changeStatus(order_details?.next_status[0])}
                  </button>
                </div>
              )} */}
              <Link href={`/review/${order_details?.reference_number}`}>
                <a className="  bg-primary py-1 px-3  text-white rounded-card   ">
                  {Orders_text.orderdetail.review_product}
                </a>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShipmentDetails;
