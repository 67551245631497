import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import SearchBox2 from '../SearchBox/SearchBox2';
import { getThumbnailImage } from '../Shared/Constant/Constant';
import HeaderNav from './HeaderNav';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import Drawer2 from './Drawer2';
import OutsideClickHandler from 'react-outside-click-handler';
import { getCookie } from 'cookies-next';
import { Menubar_text } from '../../constant/Translation/Heading';
import { useDispatch } from 'react-redux';
import { categories } from '../../store/feature/categorySlice';
import { getNotifications } from '../../store/feature/notificationSlice';
import { useRouter } from 'next/router';

const Header5 = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showUserMenus, setShowUserMenus] = useState(false);

  // Effect
  useEffect(() => {
    dispatch(
      categories({
        prams: { parent: 0, type: 'listings' },
      })
    );
  }, []);

  //Notifications Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        getNotifications({
          page: 1,
        })
      );
    }
  }, [router]);

  const drawerOpen = () => {
    const drawer = document.getElementById('sideDrawer');
    drawer.classList.remove('-translate-x-full');
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    const drawer = document.getElementById('sideDrawer');
    setIsDrawerOpen(false);
  };

  return (
    <div className=" mt-0 ">
      {/* logo */}
      <div className="max-w-[1400px] mx-auto px-3 lg:px-9 py-1.5  flex items-center justify-between gap-3">
        <div className=" md:flex-none">
          <Link href="/">
            <div className="block">
              {logo && (
                <Link href="/" passHref={true}>
                  <a className=" flex items-center   relative cursor-pointer ">
                    <img
                      src={logo && getThumbnailImage(logo)}
                      style={{
                        height: `${
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ?? 50
                        }px`,
                        width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                        objectFit:
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_object_fit ??
                          'contain',
                      }}
                      alt="logo"
                    />
                  </a>
                </Link>
              )}
            </div>
          </Link>
        </div>
        <div className="hidden lg:block flex-grow   ">
          <SearchBox2 />
        </div>
        {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
          <div className="hidden lg:block  ">
            <Add_listing_button
              title={Menubar_text.more.header_sell_btn_title}
            />
          </div>
        )}
        <div className="flex-none ">
          <HeaderNav />
        </div>
      </div>

      <div className=" flex items-center justify-between px-3 pb-1.5 lg:hidden gap-3  ">
        <button
          className=" flex-none p-3 rounded-full  transition duration-400  hover:bg-[#22222213]  "
          onClick={drawerOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
        <div className=" flex-grow   ">
          <SearchBox2 />
        </div>

        <OutsideClickHandler
          onOutsideClick={() => {
            closeDrawer();
          }}
        >
          <div
            id="sideDrawer"
            className={
              isDrawerOpen
                ? 'z-50 bg-gray-50  w-[245px] fixed inset-y-0 left-0  transform  transition  ease-in-out  duration-500 overflow-y-scroll'
                : '  order-9 bg-gray-50  w-[245px] fixed inset-y-0 left-0  transform  transition -translate-x-full ease-in-out  duration-500'
            }
          >
            <div className="">{<Drawer2 closeDrawer={closeDrawer} />}</div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default Header5;
