import React, { useEffect, useState } from 'react';
import SigninButton from './SigninButton';
import Link from 'next/link';
import HeaderProfile4 from '../HeaderProfileBox/HeaderProfile4';
import Notifications2 from '../NotificationsButton/Notifications2';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { cart_icon, wishlist_icon } from '../Shared/Constant/Icons/AllIcons';
import { getNotifications } from '../../store/feature/notificationSlice';

const HeaderNav = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { login } = useSelector(authSelector);

  //Notifications Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        getNotifications({
          page: 1,
        })
      );
    }
  }, [router]);

  return (
    <div className=" relative flex items-center gap-2 z-50 ">
      {!getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) ? (
        <div>
          <SigninButton />
        </div>
      ) : (
        <>
          <div>
            <Link href="/wishlist">
              <a className=" block p-3 rounded-full  transition duration-400  text-[#6e686e] hover:bg-[#22222213] ">
                {wishlist_icon}
              </a>
            </Link>
          </div>
          <div className=" relative">
            <Notifications2 />
          </div>
          <div id={'header_profile_items'}>
            <HeaderProfile4 />
          </div>
        </>
      )}

      <div id={'header_cart_items'}>
        <Link
          href={
            getCookie(`${TYPE_CONSTANT.DOMAIN}_login`)
              ? '/checkout'
              : '/sign-in?to=/checkout'
          }
        >
          <a className=" block p-3 rounded-full  transition duration-400  hover:bg-[#22222213]  text-[#6e686e]">
            {cart_icon}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default HeaderNav;
