/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Head from 'next/head';
import Header4 from '../../components/Header/Header4';
import CustomFooter from '../../components/Footer/CustomFooter';
import Header8 from '../../components/Header/Header8';
import Footer2 from '../../components/Footer/Footer2';
import Sub_footer from '../../components/Footer/Sub_footer';
import Header12 from '../../components/Header/Header12';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  const [showUserMenus, setShowUserMenus] = useState(false);
  return (
    <>
      <div className="bg-white">
        <Header12 />
        <div
          className="  min-h-screen   relative   "
          id="template-components-body"
        >
          <div>{children}</div>
        </div>
        <div className="  bg-footer  min-h-[200px]  left-0 bottom-0  ">
          <div className="  mt-10  max-w-[1140px] relative   mx-auto px-4  ">
            <Footer2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
