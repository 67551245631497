import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import tradly from 'tradly';
import { authSelector } from '../../../store/feature/authSlice';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import axios from 'axios';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import CategoryDropDown from '../../CatgoryDropDown/CategoryDropDown';
import CategoryDropDown2 from '../../CatgoryDropDown/CategoryDropDown2';
import { categorySelector } from '../../../store/feature/categorySlice';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import CategoryDropDown3 from '../../CatgoryDropDown/CategoryDropdown3';
import { slug as SlugChecker } from 'github-slugger';
import BuilderCategoryDropdown from '../../CatgoryDropDown/BuilderCategoryDropdown';

const BuilderHeaderCategories = () => {
  const router = useRouter();
  const { all_categories: categories } = useSelector(categorySelector);

  return (
    <div className="flex items-center gap-2  ">
      <div className="flex items-center gap-2 h-11    flex-wrap  overflow-hidden   justify-start">
        {categories?.map((item, index) => {
          const query = {
            slug: item.slug
              ? item.slug
              : `${item?.id}-${SlugChecker(item.name)}`,
          };

          if (
            item?.sub_category?.length > 0 &&
            TYPE_CONSTANT?.GENERAL_CONFIGS?.sub_category_enabled !== false
          ) {
            return (
              <BuilderCategoryDropdown
                key={item.id}
                categories={item.sub_category}
                current_category={item}
                parent_item={item}
              />
            );
          } else {
            return (
              <Link
                key={item.id}
                href={{
                  pathname: `/lc/[slug]`,
                  query,
                }}
                passHref
              >
                <a className="block" style={{ visibility: 'visible' }}>
                  <p
                    className={[
                      'flex-shrink-0 min-h-[44px] px-1  header-menu-text',
                      router?.query?.slug ==
                        (item.slug
                          ? item.slug
                          : `${item?.id}-${SlugChecker(item.name)}`) &&
                        '!text-[color:var(--headerMenuHoverTextColor)]',
                    ].join(' ')}
                  >
                    {item.name}
                  </p>{' '}
                </a>
              </Link>
            );
          }
        })}
      </div>
      {categories?.length > 8 && (
        <div className="flex-none">
          <Link
            href={{
              pathname: '/lc',
            }}
            passHref
          >
            <a className="block">
              <p className="min-h-[44px] px-1 header-menu-text">
                {Menubar_text.more.more}
              </p>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default BuilderHeaderCategories;
