/* eslint-disable react/prop-types */
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector, logout } from '../../store/feature/authSlice';
import { useRouter } from 'next/dist/client/router';

import { myStore, storeSelector } from '../../store/feature/storeSlice';
import {
  account_menus,
  customer_menus,
  custom_customer_menus,
} from './desktopMenu';
import CustomDropdown from '../Shared/utils/CustomDropdown';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menubar_text } from '../../constant/Translation/Heading';
import { getCookie } from 'cookies-next';
import { all_menus } from './menues';
import { all_menus_2 } from './menues2';

const HeaderProfile6 = () => {
  const router = useRouter();
  let userDetails = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useDispatch();
  const { first_name, profile_pic, login } = useSelector(authSelector);
  const { my_stores } = useSelector(storeSelector);

  return (
    <>
      <div
        className="bg-transparent flex items-center  cursor-pointer     relative "
        // onClick={openUserMenu}
      >
        {login && (
          <CustomDropdown
            title={
              <div className="flex rtl:flex-row-reverse items-center ">
                {profile_pic !== undefined && profile_pic !== '' ? (
                  <div className="  w-12 h-12 relative rounded-full overflow-hidden">
                    <img
                      alt="profile_pic"
                      src={profile_pic}
                      className="h-full w-full object-cover object-left-top"
                    />
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="  w-12 h-12 md:h-10 md:w-10 text-[#6e686e]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                <p className=" hidden lg:block text-base font-medium text-primary  lg:ml-2">
                  {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`)
                    ? first_name
                    : Menubar_text.more.welcome}
                </p>
                <svg
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" w-[6px] h-[4px] md:w-[12px] md:h-[8px] ml-[5px] lg:ml-4 hidden md:block"
                >
                  <path
                    d="M11.0002 1.17019C10.8128 0.983936 10.5594 0.879395 10.2952 0.879395C10.031 0.879395 9.77756 0.983936 9.59019 1.17019L6.00019 4.71019L2.46019 1.17019C2.27283 0.983936 2.01938 0.879395 1.75519 0.879395C1.49101 0.879395 1.23756 0.983936 1.05019 1.17019C0.956464 1.26315 0.88207 1.37375 0.831301 1.49561C0.780533 1.61747 0.754395 1.74818 0.754395 1.88019C0.754395 2.0122 0.780533 2.1429 0.831301 2.26476C0.88207 2.38662 0.956464 2.49722 1.05019 2.59019L5.29019 6.83019C5.38316 6.92392 5.49376 6.99831 5.61562 7.04908C5.73747 7.09985 5.86818 7.12599 6.00019 7.12599C6.1322 7.12599 6.26291 7.09985 6.38477 7.04908C6.50663 6.99831 6.61723 6.92392 6.71019 6.83019L11.0002 2.59019C11.0939 2.49722 11.1683 2.38662 11.2191 2.26476C11.2699 2.1429 11.296 2.0122 11.296 1.88019C11.296 1.74818 11.2699 1.61747 11.2191 1.49561C11.1683 1.37375 11.0939 1.26315 11.0002 1.17019Z"
                    fill="#959393"
                  />
                </svg>
              </div>
            }
          >
            {/* <div className=" rtl:hidden w-[15px] h-[15px] bg-[#fff] absolute   right-0  transform rotate-45  -top-2  ltr:mr-4 rtl:ml-4    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" /> */}
            {login && (
              <>
                <div className="flex flex-col ms:flex-row items-start justify-start ">
                  <div className="w-full">
                    {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
                      <p className=" text-base  text-primary font-semibold  transition duration-500 px- px-[25px] mb-1">
                        {Menubar_text.more.customer}
                      </p>
                    )}
                    <p className="  text-[#6e686e] font-semibold  transition duration-500 px- px-[25px] my-1   xxs:whitespace-nowrap">{` ${Menubar_text.more.welcome}  ${userDetails?.first_name} ${userDetails?.last_name}`}</p>

                    {all_menus_2({
                      store_details: my_stores ? my_stores[0] : undefined,
                      TYPE_CONSTANT: TYPE_CONSTANT,
                      Menubar_text: Menubar_text,
                    }).map((item) => {
                      if (item.is_show && item.type === 'customer') {
                        return (
                          <Link href={item.endpoint} passHref={true}>
                            <a className="   flex items-center  gap-4 cursor-pointer    px- px-[25px] py-2  hover:bg-[#f2f4f4] group">
                              <span className="text-[#6e686e] group-hover:text-primary">
                                {item.icon}
                              </span>
                              <span className=" text-sm  text-[#222222]  font-semibold  transition duration-300 group-hover:text-primary whitespace-nowrap">
                                {item.title}
                              </span>
                            </a>
                          </Link>
                        );
                      } else if (TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1) {
                        return (
                          <Link
                            href={
                              my_stores?.length > 0
                                ? item.endpoint
                                : '/a/create-account'
                            }
                            passHref={true}
                          >
                            <a className="flex items-center  gap-4 cursor-pointer w-auto   px-[25px] py-2  hover:bg-[#f2f4f4] group">
                              <span className="text-[#6e686e] group-hover:text-primary">
                                {item.icon}
                              </span>
                              <span className=" text-sm  text-[#222222]  font-semibold  transition duration-300 group-hover:text-primary whitespace-nowrap">
                                {item.title}
                              </span>
                            </a>
                          </Link>
                        );
                      }
                    })}
                    <div
                      className=" hidden ms:flex items-center  gap-4 cursor-pointer w-auto  px- px-[25px] py-2  hover:bg-[#f2f4f4] group"
                      onClick={() => {
                        dispatch(logout({ router }));
                        window.location.reload();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6   text-[#6e686e] group-hover:text-primary"
                        fill="none"
                        viewBox="0 0 24 24 "
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <span className=" text-sm  text-[#222222]  font-semibold transition duration-300 group-hover:text-primary whitespace-nowrap">
                        {Menubar_text.more.logging_out}
                      </span>
                    </div>
                  </div>

                  <div
                    className=" ms:hidden flex items-center  gap-4 cursor-pointer w-auto  px- px-[25px] py-2  hover:bg-[#f2f4f4] group"
                    onClick={() => {
                      dispatch(logout({ router }));
                      window.location.reload();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6   text-[#6e686e] group-hover:text-primary"
                      fill="none"
                      viewBox="0 0 24 24 "
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className=" text-sm  text-[#222222]  font-semibold transition duration-300 group-hover:text-primary whitespace-nowrap">
                      {Menubar_text.more.logging_out}
                    </span>
                  </div>
                </div>
              </>
            )}
          </CustomDropdown>
        )}
        <div
          id="profileMenus"
          className={
            '   group-hover:block  fixed top-0   z-[60]  mt-[55px]    transition duration-1000 hidden'
          }
        ></div>
      </div>
    </>
  );
};

export default HeaderProfile6;
