import React from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { useDispatch, useSelector } from 'react-redux';
import { storeSelector } from '../../../store/feature/storeSlice';
import { all_menus } from '../menues';
import { Menubar_text } from '../../../constant/Translation/Heading';
import Link from 'next/link';
import { logout } from '../../../store/feature/authSlice';
import { useRouter } from 'next/router';

const DefaultMenuComponent = () => {
  const { my_stores } = useSelector(storeSelector);
  let userDetails = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <div
      className={[
        'bg-[#fff] rounded-card  mt-[10px] md:mt-0      pt-[20px] pb-[15px]   min-h-[100px]  border border-[rgba(250, 250, 250, 0.93)]  shadow-sm  relative',
      ]}
    >
      <div className=" hidden md:block rtl:md:hidden w-[15px] h-[15px] bg-[#fff] absolute   right-0  transform rotate-45  -top-2  ltr:mr-4 rtl:ml-4    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" />
      <div className=" max-h-[70VH] overflow-y-auto">
        <div
          className={[
            'grid   grid-cols-[250px]',
            my_stores?.length > 0 &&
              ' ms:grid-cols-[200px,200px] sm:grid-cols-[250px,250px]',
          ].join(' ')}
        >
          <div className="w-full">
            {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
              <p className=" text-base  text-primary font-semibold  transition duration-500 px- px-[25px] mb-1">
                {Menubar_text.more.customer}
              </p>
            )}
            <p className="  text-[#6e686e] font-semibold  transition duration-500 px- px-[25px] my-1   xxs:whitespace-nowrap">{` ${Menubar_text.more.welcome}  ${userDetails?.first_name} ${userDetails?.last_name}`}</p>

            {all_menus({
              store_details: my_stores ? my_stores[0] : undefined,
              TYPE_CONSTANT: TYPE_CONSTANT,
              Menubar_text: Menubar_text,
            }).map((item) => {
              if (item.is_show && item.type === 'customer') {
                return (
                  <Link href={item.endpoint} passHref={true}>
                    <a className="   flex items-center  gap-4 cursor-pointer    px- px-[25px] py-2  hover:bg-[#f2f4f4] text-[#6e686e] hover:text-primary">
                      <span className=" ">{item.icon}</span>
                      <span className=" text-sm  font-semibold  transition duration-300 whitespace-nowrap">
                        {item.title}
                      </span>
                    </a>
                  </Link>
                );
              }
            })}
            <div
              className=" hidden ms:flex items-center  gap-4 cursor-pointer w-auto  px- px-[25px] py-2  hover:bg-[#f2f4f4]  text-[#6e686e] hover:text-primary"
              onClick={() => {
                dispatch(logout({ router }));
                window.location.reload();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6   "
                fill="none"
                viewBox="0 0 24 24 "
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span className=" text-sm    font-semibold transition duration-300   whitespace-nowrap">
                {Menubar_text.more.logging_out}
              </span>
            </div>
          </div>
          {my_stores?.length > 0 && (
            <div className="w-full mt-2 ms:mt-0">
              <p className=" text-base  text-primary font-semibold  transition duration-500  px-[25px] mb-1">
                {Menubar_text.more.business}
              </p>

              {all_menus({
                store_details: my_stores ? my_stores[0] : undefined,
                TYPE_CONSTANT: TYPE_CONSTANT,
                Menubar_text: Menubar_text,
              }).map((item) => {
                if (item.is_show && item.type === 'business') {
                  return (
                    <Link href={item.endpoint} passHref={true}>
                      <a className="flex items-center  gap-4 cursor-pointer w-auto   px-[25px] py-2  hover:bg-[#f2f4f4]  text-[#6e686e] hover:text-primary">
                        <span className="">{item.icon}</span>
                        <span className=" text-sm    font-semibold  transition duration-300   whitespace-nowrap">
                          {item.title}
                        </span>
                      </a>
                    </Link>
                  );
                }
              })}
            </div>
          )}
          <div
            className=" ms:hidden flex items-center  gap-4 cursor-pointer w-auto  px- px-[25px] py-2  hover:bg-[#f2f4f4]  text-[#6e686e] hover:text-primary"
            onClick={() => {
              dispatch(logout({ router }));
              window.location.reload();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6    "
              fill="none"
              viewBox="0 0 24 24 "
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <span className=" text-sm  font-semibold transition duration-300 whitespace-nowrap">
              {Menubar_text.more.logging_out}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultMenuComponent;
