import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const CardBlockImageFirst = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
        {data?.cards?.map((item) => {
          return (
            <div style={get_dynamic_styles(styles?.card_styles)}>
              {/* image */}
              {item?.image && (
                <img
                  style={get_dynamic_styles(styles?.image)}
                  src={item?.image}
                  alt="image"
                />
              )}
              {/* Title */}
              {item?.title && (
                <p style={get_dynamic_styles(styles?.title)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'title',
                    default_data: item?.title,
                  })}
                </p>
              )}
              {/* Desc */}
              {item?.desc && (
                <p style={get_dynamic_styles(styles?.desc)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'desc',
                    default_data: item?.desc,
                  })}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardBlockImageFirst;
