/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Head from 'next/head';
import Header4 from '../../components/Header/Header4';
import CustomFooter from '../../components/Footer/CustomFooter';
import Sub_footer from '../../components/Footer/Sub_footer';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const CustomLayout = ({ children, pageTitle, pageDescription }) => {
  const [showUserMenus, setShowUserMenus] = useState(false);
  return (
    <>
      <div>
        <Header4 />
        <div
          className="mt-4  min-h-screen  overflow-x-hidden max-w-6xl mx-auto px-4 sm:px-6"
          id="template-components-body"
        >
          <div>{children}</div>
        </div>
        <div className=" min-h-[200px]  left-0 bottom-0   ">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer !== false && (
            <div className=" ">
              <Sub_footer title={'Explore the APP'} />
            </div>
          )}
          <div className="bg-footer     ">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomLayout;
