import {
  invite_friend,
  invite_friend_2,
  plus_icon_2,
  privacy_icon,
  privacy_icon_2,
  store_icon_2,
} from '../Shared/Constant/Icons/AllIcons';

export const all_menus = ({ store_details, Menubar_text, TYPE_CONSTANT }) => {
  return [
    {
      id: 1,
      type: 'customer',
      title: Menubar_text.more.profile,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      ),
      endpoint: '/edit-profile',
      is_show: true,
    },
    {
      id: 1.1,
      type: 'customer',
      title: Menubar_text.more.wallet,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 7H18V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7956 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7956 22 18V10C22 9.20435 21.6839 8.44129 21.1213 7.87868C20.5587 7.31607 19.7956 7 19 7ZM5 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V7H5C4.73478 7 4.48043 6.89464 4.29289 6.70711C4.10536 6.51957 4 6.26522 4 6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5ZM20 15H19C18.7348 15 18.4804 14.8946 18.2929 14.7071C18.1054 14.5196 18 14.2652 18 14C18 13.7348 18.1054 13.4804 18.2929 13.2929C18.4804 13.1054 18.7348 13 19 13H20V15ZM20 11H19C18.2044 11 17.4413 11.3161 16.8787 11.8787C16.3161 12.4413 16 13.2044 16 14C16 14.7956 16.3161 15.5587 16.8787 16.1213C17.4413 16.6839 18.2044 17 19 17H20V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H5C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18V8.83C4.32127 8.94302 4.65943 9.00051 5 9H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V11Z"
            fill="currentColor"
          />
        </svg>
      ),
      endpoint: '/wallet',
      is_show: TYPE_CONSTANT?.PAYMENT_CONFIGS?.wallet_enabled ? true : false,
    },
    {
      id: 2,
      type: 'customer',
      title: Menubar_text.more.my_cart,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6   "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
      ),
      endpoint: '/checkout',
      is_show: TYPE_CONSTANT.MARKETPLACE_MODULES === 1 ? true : false,
    },
    {
      id: 3,
      type: 'customer',
      title: Menubar_text.more.my_orders,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
          />
        </svg>
      ),
      endpoint: '/orders',
      is_show: true,
    },
    {
      id: 4,
      type: 'customer',
      title: Menubar_text.more.social_feed,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6  "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
          />
        </svg>
      ),
      endpoint: '/social_feed',
      is_show: TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 ? true : false,
    },
    {
      id: 5,
      type: 'customer',
      title: Menubar_text.more.more_stores_i_follow,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6  "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      ),
      endpoint: '/a/following',
      is_show: TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 ? true : false,
    },
    {
      id: 6,
      type: 'customer',
      title: Menubar_text.more.more_stored_i_blocked,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      ),
      endpoint: '/a/blocked',
      is_show: TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 ? true : false,
    },
    {
      id: 7,
      type: 'customer',
      title: Menubar_text.more.my_address,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6 "
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
      ),
      endpoint: '/addresses',
      is_show: true,
    },

    {
      id: 8,
      type: 'customer',
      title: Menubar_text.more.terms_condition,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6    "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          />
        </svg>
      ),
      endpoint: TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url,
      is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url ? true : false,
    },
    {
      id: 9,
      type: 'customer',
      title: Menubar_text.more.privacy_policy,
      icon: privacy_icon_2,
      endpoint: TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy,
      is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy ? true : false,
    },
    {
      id: 10,
      type: 'customer',
      title: Menubar_text.more.tell_a_friend,
      icon: invite_friend_2,
      endpoint: '/invite',
      is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.invite_friends_collection_enabled
        ? true
        : false,
    },
    {
      id: 11,
      type: 'customer',
      title: Menubar_text.more.feedback,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6  "
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
        </svg>
      ),
      endpoint: '/feedback',
      is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.enable_feedback ? true : false,
    },
    {
      id: 12,
      type: 'business',
      title: Menubar_text.more.my_store,
      icon: store_icon_2,
      endpoint: '/a/my-account',
      is_show: true,
    },
    {
      id: 13,
      type: 'business',
      title: Menubar_text.more.my_store_orders,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
          />
        </svg>
      ),
      endpoint: `/a/orders?account_id=${store_details?.id}`,
      is_show: true,
    },
    {
      id: 14,
      type: 'business',
      title: Menubar_text.more.my_sales,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke-width="2"
          className="h-6 w-6     "
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
          />
        </svg>
      ),
      endpoint: `/transactions?account_id=${store_details?.id}`,
      is_show: true,
    },
    {
      id: 15,
      type: 'business',
      title: Menubar_text.more.payments,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6   "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
      ),
      endpoint: `/payout`,
      is_show: TYPE_CONSTANT.PAYMENT_CONFIGS?.stripe_enabled ? true : false,
    },
    {
      id: 16,
      type: 'business',
      title: Menubar_text.more.add_new_listing,
      icon: plus_icon_2,
      endpoint: `/a/add-listing?account_id=${store_details?.id}`,
      is_show: store_details ? true : false,
    },
  ];
};
