/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Head from 'next/head';
import Header4 from '../../components/Header/Header4';
import CustomFooter from '../../components/Footer/CustomFooter';
import Header8 from '../../components/Header/Header8';
import Footer2 from '../../components/Footer/Footer2';
import Sub_footer from '../../components/Footer/Sub_footer';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  const [showUserMenus, setShowUserMenus] = useState(false);
  return (
    <>
      <div>
        <Header8 />
        <div
          className="  min-h-screen   mx-auto  "
          id="template-components-body"
        >
          <div>{children}</div>
        </div>
        <div className="    min-h-[200px]  left-0 bottom-0  ">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer !== false && (
            <div>
              <Sub_footer title={'Add Course'} />
            </div>
          )}
          <div className="  bg-footer mt-10 ">
            <Footer2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
