/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import Link from 'next/link';
import { Orders_text } from '../../../constant/Translation/OrdersText';

const ItemsSummary = ({ order_details }) => {
  const OrderDetails = order_details?.order_details;
  return (
    <div className=" w-full h-min-[300px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7   border-opacity-40  ">
      <div className=" grid  grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%] pb-3 border-b-2 border-gray-400     bg-white z-20">
        <p className=" text-sm sm:text-lg text-black font-semibold ">
          {Orders_text.orderdetail.items_summary}
        </p>
        <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
          {Orders_text.orderdetail.qty}
        </p>
        <p className=" hidden md:block text-lg text-black font-semibold  text-center">
          {Orders_text.orderdetail.price}
        </p>
        <p className="text-sm sm:text-lg text-black font-semibold  text-center">
          {Orders_text.orderdetail.total_price}
        </p>
      </div>
      <div>
        {OrderDetails?.map((item, index) => {
          return (
            <Link
              key={Math.random()}
              href={
                item?.listing?.slug
                  ? `/l/${item?.listing?.slug}`
                  : `/l/${item?.listing?.id}-${item?.listing?.title.replace(
                      /[ /?,#=]+/g,
                      '-'
                    )}?id=true`
              }
            >
              <a
                className={[
                  '  grid  grid-cols-[50%,20%,30%]   md:grid-cols-[40%,20%,20%,20%] items-center py-3  cursor-pointer',
                  index !== OrderDetails.length - 1 &&
                    'border-b-2 border-gray-400 border-opacity-20',
                ].join(' ')}
              >
                <div
                  className=" flex items-center ltr:pl-1 rtl:pr-1"
                  key={item.id}
                >
                  {item?.listing?.images?.length > 0 && (
                    <img
                      src={getThumbnailImage(item?.listing.images[0])}
                      className="h-[50px] w-[50px] object-cover object-left-top"
                      alt="image"
                    />
                  )}
                  <p className=" text-sm font-semibold  ltr:ml-3 rtl:mr-3">
                    {item.listing.title}
                  </p>
                </div>
                <div>
                  <p className=" text-sm font-semibold  text-center">
                    X {item.quantity}
                  </p>
                </div>
                <div className=" hidden md:block">
                  <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                    {item.list_price.formatted}
                  </p>
                </div>
                <div>
                  <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                    {item.list_price.formatted}
                  </p>
                </div>
              </a>
            </Link>
          );
        })}
      </div>
      <div className="flex justify-end mt-2">
        <Link href={`/review/${order_details?.reference_number}`}>
          <a className="  bg-primary py-1 px-3  text-white rounded-card   ">
            {Orders_text.orderdetail.review_product}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ItemsSummary;
