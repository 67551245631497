import React, { Fragment, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { cn } from '../../utils/classNames';
import AllIcons from '../Shared/Constant/Icons/AllIcons';
import { get_data_from_local_storage } from '../../utils/localStorage';

const Language_switch = ({ id_name = 'header_language_switch_button' }) => {
  const [languages, setLanguages] = useState(
    get_data_from_local_storage({ key_name: 'available_languages' })
  );
  const [selected_language_code, setSelectedLanguageCode] = useState(
    getCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`)
  );
  const [selected_language_details, setSelectedLanguageDetails] = useState([]);
  TYPE_CONSTANT.DIR = getCookie(`${TYPE_CONSTANT.DOMAIN}_direction`);
  TYPE_CONSTANT.SELECTED_LANGUAGE = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_language`
  );

  let selected_language_image = selected_language_details?.image_path
    ? selected_language_details?.image_path
    : selected_language_details?.flag
    ? selected_language_details?.flag
    : undefined;

  //
  useEffect(() => {
    if (languages?.length > 0) {
      setSelectedLanguageDetails(
        languages?.filter(
          (language) => language.code == selected_language_code
        )?.[0]
      );
    }
  }, [languages, selected_language_code]);

  //
  const language_change = (code, rtl) => {
    setCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`, code);
    setCookie(`${TYPE_CONSTANT.DOMAIN}_direction`, rtl ? 'rtl' : 'ltr');
    window.location.reload();
  };

  //current_button_position
  const [dropdown_position, setDropdownPositionState] = useState({
    top: null,
    align_items: 'items-end',
  });
  const buttonRect = document?.getElementById(id_name)?.getBoundingClientRect();
  useEffect(() => {
    const getButtonPosition = () => {
      if (buttonRect) {
        setDropdownPositionState({
          top: buttonRect.top + buttonRect.height + 5,
          align_items:
            buttonRect?.left > buttonRect?.right ? 'items-start' : 'items-end',
        });
      }
    };

    // Call the function to get button position after the component has rendered
    getButtonPosition();
  }, [buttonRect?.x]);

  return (
    languages?.length > 1 && (
      <div className=" text-right ltr:mr-1 rtl:ml-1 ltr:md:mr-0 rtl:md:ml-0  inline-block  ">
        <Menu
          as="div"
          className={cn(
            ' flex flex-col justify-center  ',
            dropdown_position?.align_items
          )}
        >
          <div>
            <Menu.Button className="inline-flex rtl:flex-row-reverse justify-center items-center w-full text-sm font-medium  p-2 rounded-full  transition duration-400  hover:bg-[#22222213]  ">
              {selected_language_details ? (
                selected_language_image ? (
                  <img
                    src={selected_language_image}
                    alt="flag image"
                    className="h-6 w-6 rounded-full  object-cover"
                  />
                ) : (
                  AllIcons.language_icon_2
                )
              ) : (
                AllIcons.language_icon_2
              )}

              <ChevronDownIcon
                className="w-5 h-5 text-[#6e686e]   -mr-1    "
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as={'div'}
              className={[
                `fixed  min-w-[120px]  origin-top-left bg-white     rounded-card   shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none   z-50   `,
                languages?.length > 2 ? 'h-40  overflow-y-auto' : 'h-28 ',
              ].join(' ')}
              style={{
                top: `${dropdown_position?.top}px`,
              }}
            >
              {/* <div className="w-[15px] h-[15px] bg-[#fff] hidden md:block absolute    md:right-0   transform rotate-45  -top-2   ltr:mr-7 rtl:ml-7    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" /> */}

              <div className="  py-1">
                <div className="  overflow-auto    scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-300   ">
                  {languages?.map((language) => {
                    let language_image = language?.image_path
                      ? language?.image_path
                      : language?.flag
                      ? language?.flag
                      : undefined;
                    return (
                      <Menu.Item>
                        <button
                          className={[
                            ' w-full flex items-center  justify-start hover:text-primary mb-2 shadow-sm px-2 py-1 rounded-sm gap-3',
                            selected_language_code == language.code
                              ? 'text-primary'
                              : 'text-[#6e686e]',
                          ].join(' ')}
                          onClick={() =>
                            language_change(language.code, language?.rtl)
                          }
                        >
                          {language_image ? (
                            <img
                              src={language_image}
                              alt="flag image"
                              className="h-7 w-7 rounded-full  object-cover shadow-sm"
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="h-7 w-7 rounded-full bg-gray-200 p-1 shadow-sm "
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                              />
                            </svg>
                          )}
                          <p className="text-base font-medium  leading-none">
                            {language.name}
                          </p>
                        </button>
                      </Menu.Item>
                    );
                  })}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    )
  );
};

export default Language_switch;
