/* eslint-disable react/prop-types */

import { Orders_text } from '../../../constant/Translation/OrdersText';

const OrderTracking = ({ order_details }) => {
  return (
    <>
      <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
        <div className="flex  ">
          <p className=" text-lg text-black font-semibold   ">
            {Orders_text?.orderdetail.shipment_details}
          </p>
        </div>
        {order_details?.shipment ? (
          <div className="mt-3">
            {order_details?.shipment?.provider && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail.provider} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.provider}
                </p>
              </div>
            )}
            {order_details?.shipment?.tracking_url && (
              <a
                href={order_details?.shipment?.tracking_url}
                target="_blank"
                rel="noreferrer"
                className=" flex justify-start  items-start  py-1  "
              >
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_url} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.tracking_url}
                </p>
              </a>
            )}
            {order_details?.shipment?.tracking_number && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_number} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.tracking_number}
                </p>
              </div>
            )}
          </div>
        ) : (
          <p className=" mt-3 text-sm text-gray-800 font-medium   break-words ">
            {Orders_text.orderdetail.shipment_data_unavailable_note}
          </p>
        )}
      </div>
    </>
  );
};

export default OrderTracking;
