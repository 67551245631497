import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import DisclosureComponent from '../Shared/utils/Disclousere';
import MobileCategoryChildLevel from './MobileCategoryChildLevel';
import { slug as SlugChecker } from 'github-slugger';

const MobileCategoryDropdown = ({
  categories,
  current_category,
  main_category_style,
}) => {
  const router = useRouter();

  return (
    <div>
      <DisclosureComponent
        main_btn={
          <button className="text-start">{current_category.name} </button>
        }
        main_btn_css={[
          'w-full h-12  !whitespace-pre-wrap    px-5  justify-between hover:bg-[var(--headerMenuHoverBackgroundColor)] header-menu-text mb-1   text-start',
        ].join(' ')}
        main_btn_open_css={[
          'w-full h-12  !whitespace-pre-wrap    px-5  justify-between bg-[var(--headerMenuHoverBackgroundColor)] header-menu-text mb-1   text-start',
        ].join(' ')}
        sub_buttons={categories.map((category) => {
          const query =
            category.name !== 'More'
              ? {
                  slug: current_category.slug
                    ? category.slug
                    : `${current_category?.id}-${SlugChecker(
                        current_category.name
                      )}`,
                }
              : '';

          return (
            <div className="">
              <Link
                href={{
                  pathname: `${
                    category.name !== 'More' ? '/lc/[slug]' : '/lc'
                  }`,
                  query,
                }}
                passHref={true}
              >
                <button
                  className={[
                    'w-full h-12 !whitespace-pre-wrap   px-5   text-start hover:bg-[var(--headerMenuHoverBackgroundColor)] header-menu-text mb-1  justify-start   ',
                    router?.query?.slug ==
                      (category.slug
                        ? category.slug
                        : `${category?.id}-${SlugChecker(category.name)}`) &&
                      '!text-[color:var(--headerMenuHoverTextColor)] bg-[var(--headerMenuHoverBackgroundColor)]',
                  ].join(' ')}
                >
                  {category.name}
                </button>
              </Link>
            </div>
          );
        })}
        disclosure_panel_style="w-full flex flex-col gap-1 px-3"
      />
    </div>
  );
};

export default MobileCategoryDropdown;
