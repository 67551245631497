import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SidePan from '../Modal/SidePan';
import { useState } from 'react';
import SubscriptionAlert from '../../Subscription/SubscriptionAlert';
import { cn } from '../../../utils/classNames';

const Add_listing_button = ({ title, btn_styles, icon, text_styles }) => {
  const router = useRouter();

  const [sidePanOpen, setSidePanOpen] = useState(false);

  // ! Deprecated code
  // if (
  //   TYPE_CONSTANT.PAYMENT_CONFIGS?.subscription_enabled &&
  //   !JSON.parse(
  //     Cookies.get(
  //       `${TYPE_CONSTANT.DOMAIN}_isStoreAccountSubscriptionCompleted`
  //     ) ?? 'false'
  //   )
  // ) {
  //   e.stopPropagation();
  //   setSidePanOpen(true);
  // }
  return (
    <>
      <div>
        <button
          className={cn('btn-primary-outline  ', btn_styles)}
          onClick={(e) => {
            if (localStorage.getItem('login')) {
              if (
                JSON.parse(
                  Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) ??
                    'false'
                )
              ) {
                router.push({
                  pathname: '/a/add-listing',
                  query: {
                    account_id: JSON.parse(
                      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)
                    ),
                  },
                });
              } else {
                router.push('/a/create-account');
              }
            } else {
              router.push(`/sign-in?to=${router.asPath}`);
            }
          }}
        >
          {icon && <span>{icon} </span>}
          <span className={[text_styles ?? ''].join(' ')}>{title}</span>
        </button>
      </div>
      {/* <SidePan
        id={`add-listing-subscription`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
        modal_section_styles={
          'fixed   right-0 left-0 bottom-0  top-0  my-auto  z-[60] overflow-hidden flex items-center  justify-center    rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-md w-full max-h-full   "
        modal_width={'max-w-md  w-full'}
      >
        <div className=" relative  ">
          <SubscriptionAlert
            account_id={JSON.parse(
              Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) ?? 0
            )}
            closeSidepan={() => setSidePanOpen(false)}
          />
        </div>
      </SidePan> */}
    </>
  );
};

export default Add_listing_button;
