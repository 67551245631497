/* eslint-disable react/prop-types */
import React from 'react';
import Footer from '../../components/Footer/Footer';
import Footer3 from '../../components/Footer/Footer3';
import Header8 from '../../components/Header/Header8';
import Header9 from '../../components/Header/Header9';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <>
      <div className=" bg-white ">
        <div className="  sticky  top-0 z-50 ">
          <Header9 />
        </div>
        <div
          className="  min-h-screen w-full   pt-14 pb-10  md:pb-5"
          id="template-components-body"
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6">{children}</div>
        </div>
        <div
          id="footer"
          className="bg-footer min-h-[150px]   left-0 bottom-0  max-w-6xl mx-auto px-4 sm:px-6 py-10 flex flex-col justify-end"
        >
          <Footer3 />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
