import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { common_blocks_cases } from '../../../themes/blank/BlockDecide/CommonBlockCase';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../../utils/ResponsiveDesign';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { latest_editor_version } from '../../../constant/Version';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import { footerDefaultColumnStyles } from '../../../constant/default_styles/FooterDefaultStyles';
import { storeSelector } from '../../../store/feature/storeSlice';

const DynamicProfileMenusByEditor = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const DynamicProfileMenu =
    TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_dynamic_profile_menu;
  const { my_stores } = useSelector(storeSelector);
  let userDetails = JSON.parse(localStorage.getItem('user_details'));
  const { width } = useScreenSize();

  // component decide
  const component_decide = ({
    block,
    block_code,
    isStatic,
    data,
    styles,
    isEnabled = true,
    items,
  }) => {
    const section = {
      block,
      block_code,
      isStatic,
      data,
      styles,
      isEnabled,
      items,
    };

    switch (block) {
      default:
        return common_blocks_cases({ section });
    }
  };

  // const header main styles
  const profile_main_styles = responsive_design_styles({
    parent_group: DynamicProfileMenu?.styles,
    child_group_name: 'main_styles',
    width,
  });

  return (
    <div
      className={' '}
      id="profile-menu"
      style={get_dynamic_styles(
        responsive_design_styles({
          parent_group: DynamicProfileMenu?.styles,
          child_group_name: 'main_styles',
          width,
        })
      )}
    >
      {DynamicProfileMenu?.schema?.map((row) => {
        const {
          columns,
          current_editor_version = '',
          condition_settings,
          ...column_styles
        } = row || {};
        const row_styles = responsive_row_styles({
          parent_group: row,
          width,
        });
        const is_show_for_buyer = condition_settings?.show_for_buyer
          ? my_stores?.length == 0
          : my_stores?.length > 0;
        const is_show_for_seller = condition_settings?.show_for_seller
          ? my_stores?.length > 0
          : my_stores?.length == 0;

        return (
          is_show_for_buyer &&
          is_show_for_seller &&
          ((width < 768 && row_styles?.isShowInMobile !== false) ||
            (width > 768 && row_styles?.isShowInWeb !== false)) && (
            <div
              style={
                current_editor_version == latest_editor_version
                  ? {
                      display: row_styles?.display,
                      gridTemplateColumns: dynamic_text_convert({
                        main_text: row_styles?.columns_box_percentage,
                        check_by: '-1%',
                        value: '',
                      }),
                      ...get_dynamic_styles(row_styles),
                    }
                  : {
                      display:
                        row_styles?.isShowInMobile === false ? 'none' : 'grid',
                      gap: row_styles?.gap_between_columns ?? '24px',
                      gridTemplateColumns:
                        width > 768
                          ? row_styles?.columns_box_percentage
                            ? row_styles?.columns_box_percentage
                            : `repeat(${
                                row.columns?.length ?? 1
                              }, minmax(0, 1fr))`
                          : row?.['768_row_styles']
                          ? row_styles?.columns_box_percentage
                          : '100%',
                      ...get_dynamic_styles(row_styles),
                    }
              }
            >
              {/* Columns */}
              {columns.map((column, index) => {
                const current_column_style = responsive_column_styles({
                  parent_group: column_styles,
                  index,
                  width,
                });

                return (
                  <div
                    style={{
                      display: current_column_style?.display
                        ? current_column_style?.display
                        : 'flex',
                      ...get_dynamic_styles_with_default_value(
                        current_column_style,
                        footerDefaultColumnStyles
                      ),
                    }}
                  >
                    {column?.map((item) => {
                      return (
                        component_decide({ ...item }) &&
                        component_decide({ ...item })
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );
};

export default React.memo(DynamicProfileMenusByEditor);
