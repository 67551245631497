import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import { useRouter } from 'next/dist/client/router';
import SearchBox from '../SearchBox/SearchBox';
import axios from 'axios';
import Notifications3 from '../NotificationsButton/Notifications3';
import Language_switch from '../HeaderButton/Language_switch_button';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { fetch_all_categories } from '../../constant/fetch_all_categories';
import { categorySelector } from '../../store/feature/categorySlice';
import { useSelector } from 'react-redux';
import DisclosureComponent from '../Shared/utils/Disclousere';
import MobileCategoryDropdown from '../CatgoryDropDown/MobileCategoryDropdown';
import { slug as SlugChecker } from 'github-slugger';

const Drawer = () => {
  const { all_categories } = useSelector(categorySelector);
  const router = useRouter();
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLogo(localStorage.getItem('logo'));
  }, [0]);

  //decide category
  let categories = [];

  return (
    <div>
      <div className="    left-0 right-0    flex-col  justify-center     mb-2">
        <div className="flex items-center justify-between gap-1">
          {logo && (
            <Link href="/" passHref={true}>
              <a className=" w-auto block px-2      cursor-pointer py-2">
                <img
                  src={logo}
                  className="   h-[50px] object-contain"
                  alt="logo"
                />
              </a>
            </Link>
          )}
          <div id="header_notification_button_drawer">
            <Language_switch id_name={'header_notification_button_drawer'} />
          </div>

          {localStorage.getItem('login') && (
            <div
              className="flex-none   "
              id="header_language_switch_button_drawer"
            >
              <Notifications3
                id_name={'header_language_switch_button_drawer'}
              />
            </div>
          )}
        </div>

        <div className="pb-[10px] px-2">
          <SearchBox />
        </div>
      </div>
      <div>
        {all_categories?.length > 0 &&
          all_categories?.map((item) => {
            if (
              item?.sub_category?.length > 0 &&
              TYPE_CONSTANT?.GENERAL_CONFIGS?.sub_category_enabled !== false
            ) {
              return (
                <MobileCategoryDropdown
                  current_category={item}
                  categories={item.sub_category}
                />
              );
            } else {
              const query =
                item.name !== 'More'
                  ? {
                      slug: item.slug
                        ? item.slug
                        : `${item?.id}-${SlugChecker(item.name)}`,
                    }
                  : '';
              return (
                <div key={item.id}>
                  <Link
                    href={{
                      pathname: `${
                        item.name !== 'More' ? '/lc/[slug]' : '/lc'
                      }`,
                      query,
                    }}
                    passHref={true}
                  >
                    <p
                      className={[
                        ' w-full h-12    hover:bg-[var(--headerMenuHoverBackgroundColor)] mb-1 !whitespace-pre-wrap  header-menu-text justify-start px-5 ',
                        router?.query?.slug ===
                          (item.slug
                            ? item.slug
                            : `${item?.id}-${SlugChecker(item.name)}`) &&
                          '!text-[color:var(--headerMenuHoverTextColor)] bg-[var(--headerMenuHoverBackgroundColor)]',
                      ].join(' ')}
                    >
                      {item.name}
                    </p>
                  </Link>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default Drawer;
