export const FooterCardBoxDefaultStyles = {};

export const footerDefaultColumnStyles = {
  flex_direction: 'column',
  column_gap: '12px',
  row_gap: '12px',
};

export const FooterMainDefaultStyles = {
  background: '',
  width: '100%',
};
