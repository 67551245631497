import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useScreenSize } from '../../hooks/useScreenSize';
import React, { useEffect, useState } from 'react';
import { myStore } from '../../store/feature/storeSlice';
import {
  AccountCategories,
  categories,
} from '../../store/feature/categorySlice';
import DynamicSearchBox from '../SearchBox/DynamicSearchBox';
import HeaderMenuLink from '../StaticBlocks/HeaderMenuLink';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import SigninButton from '../Header/SigninButton';
import DividerBlock from '../StaticBlocks/DividerBlock';
import Heading2Block from '../StaticBlocks/Heading2Block';
import AnimatedTextBlock from '../StaticBlocks/AnimatedTextBlock';
import SocialMedia from '../Footer/sections/SocialMedia';
import CustomCta from '../StaticBlocks/CustomCta';
import { getNotifications } from '../../store/feature/notificationSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { latest_editor_version } from '../../constant/Version';
import { HeaderDefaultColumnStyles } from '../../constant/default_styles/HeaderDefaultStyles';
import { common_blocks_cases } from '../../themes/blank/BlockDecide/CommonBlockCase';

const DynamicBottomTabbar = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const DynamicBottombarStyle =
    TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_bottom_tabbar;

  const { width } = useScreenSize();

  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  // Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        myStore({
          prams: {
            page: 1,
            type: 'accounts',
            user_id: userDetails.id,
          },
        })
      );
    }
    dispatch(
      categories({
        prams: { parent: 0, type: 'listings' },
      })
    );
    dispatch(
      AccountCategories({
        prams: { parent: 0, type: 'accounts' },
      })
    );
  }, []);

  //Notifications Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        getNotifications({
          page: 1,
        })
      );
    }
  }, [router]);

  // check_unauthorized_guest
  const check_unauthorized_guest = (isShowUnauthorizedGuest) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

    return isShowUnauthorizedGuest ? true : isLoggedIN ? true : false;
  };

  // check_authorized_peop
  const check_authorized_people = (isShowAuthorizedPeople) => {
    const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);

    return isShowAuthorizedPeople ? true : isLoggedIN ? false : true;
  };

  // component decide
  const component_decide = ({
    block,
    block_code,
    isStatic,
    data,
    styles,
    isEnabled = true,
    items,
  }) => {
    const {
      isShowInMobile,
      isShowInWeb,
      isShowUnauthorizedGuest,
      isShowAuthorizedPeople,
    } = data || {};
    const section = {
      block,
      block_code,
      isStatic,
      data,
      styles,
      isEnabled,
      items,
    };

    // logo_styles
    const logo_styles = responsive_design_styles({
      width,
      parent_group: styles,
      child_group_name: 'logo_styles',
    });

    switch (block) {
      case 'logo':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <Link href="/" passHref={true}>
              <a className="  flex items-center   relative cursor-pointer  ">
                <img
                  src={logo}
                  style={{
                    // height: styles?.height ?? '50px',
                    // width: styles?.width ?? '',
                    maxHeight: logo_styles?.max_height ?? '44px',
                    width: logo_styles?.width,
                    objectFit: logo_styles?.object_fit ?? 'contain',
                  }}
                  alt="logo"
                />
              </a>
            </Link>
          )
        );

      case 'search':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <DynamicSearchBox styles={styles} />
          )
        );
      case 'custom':
        return (
          isEnabled &&
          ((isShowInMobile !== false && width < 768) ||
            (isShowInWeb !== false && width > 768)) && (
            <HeaderMenuLink data={data} />
          )
        );
      case 'add_listing_btn':
        return (
          isEnabled &&
          TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <Add_listing_button
                title={Menubar_text.more.header_sell_btn_title}
                btn_styles={'py-[9px]'}
              />
            </div>
          )
        );

      case 'sign_in_signup':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <div className={isShowInMobile ? 'block' : ' hidden md:block  '}>
              <SigninButton />
            </div>
          )
        );
      case 'divider_block':
        return <DividerBlock styles={styles} />;

      case 'heading2_block':
        return <Heading2Block data={data} styles={styles} />;
      case 'animated_paragraph':
        return <AnimatedTextBlock data={data} styles={styles} />;
      case 'social_links':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <SocialMedia data={data} styles={styles} />
          )
        );
      case 'cta':
        return (
          isEnabled &&
          check_unauthorized_guest(isShowUnauthorizedGuest) &&
          check_authorized_people(isShowAuthorizedPeople) &&
          ((width < 768 && isShowInMobile !== false) ||
            (width > 768 && isShowInWeb !== false)) && (
            <CustomCta data={data} styles={styles} />
          )
        );

      default:
        return common_blocks_cases({ section });
    }
  };

  // const header main styles
  const main_styles = responsive_design_styles({
    parent_group: DynamicBottombarStyle?.styles,
    child_group_name: 'main_styles',
    width,
  });

  return (
    <>
      <div style={get_dynamic_styles(main_styles)}>
        <div
          className={` relative   mx-auto`}
          style={get_dynamic_styles(
            responsive_design_styles({
              parent_group: DynamicBottombarStyle?.styles,
              child_group_name: 'box_styles',
              width,
            })
          )}
          id="header_section"
        >
          {/* Rows */}

          {DynamicBottombarStyle?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });
            return current_editor_version === latest_editor_version
              ? row_styles?.display !== 'none' && (
                  <div
                    style={{
                      display: row_styles?.display,
                      gridTemplateColumns: dynamic_text_convert({
                        main_text: row_styles?.columns_box_percentage,
                        check_by: '-1%',
                        value: '',
                      }),
                      ...get_dynamic_styles(row_styles),
                    }}
                  >
                    {/* Columns */}
                    {columns.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          style={{
                            display: current_column_style?.display
                              ? current_column_style?.display
                              : 'flex',
                            ...get_dynamic_styles_with_default_value(
                              current_column_style,
                              HeaderDefaultColumnStyles
                            ),
                          }}
                        >
                          {column?.map((item) => {
                            return (
                              component_decide({ ...item }) &&
                              component_decide({ ...item })
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )
              : ((width < 768 && row_styles?.isShowInMobile !== false) ||
                  (width > 768 && row_styles?.isShowInWeb !== false)) && (
                  <div
                    className={`flex items-center `}
                    style={{
                      justifyContent: row_styles?.justify_content,
                      gap: row_styles?.gap_between_columns,
                    }}
                  >
                    {/* Columns */}
                    {columns.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          className={[
                            `flex  `,
                            current_column_style?.isOverFollowHidden &&
                              'flex-wrap  overflow-hidden justify-start',
                            current_column_style?.isFlexNone
                              ? 'flex-none'
                              : 'flex-grow',
                          ].join(' ')}
                          style={get_dynamic_styles_with_default_value(
                            current_column_style,
                            HeaderDefaultColumnStyles
                          )}
                        >
                          {column?.map((item) => {
                            return (
                              component_decide({ ...item }) &&
                              component_decide({ ...item })
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
          })}
        </div>
      </div>
    </>
  );
};

export default React.memo(DynamicBottomTabbar);
