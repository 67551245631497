import React, { useEffect, useState } from 'react';
import SearchBox from '../SearchBox/SearchBox';
import StoreButton from './StoreButton';
import Link from 'next/link';
import HeaderCategories from './HeaderCategories';
import OutsideClickHandler from 'react-outside-click-handler';
import Drawer from './Drawer';
import WishListButton from '../WishListButton/WishListButton';
import HeaderProfile2 from '../HeaderProfileBox/HeaderProfile2';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import Cart_button from '../HeaderButton/Cart_button';
import Notifications3 from '../NotificationsButton/Notifications3';
import { getCookie } from 'cookies-next';
import Language_switch from '../HeaderButton/Language_switch_button';
import { Menubar_text } from '../../constant/Translation/Heading';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { myStore } from '../../store/feature/storeSlice';
import { categories } from '../../store/feature/categorySlice';
import { getNotifications } from '../../store/feature/notificationSlice';
import TagLine from '../Shared/Logo/TagLine';

const Header3 = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showUserMenus, setShowUserMenus] = useState(false);

  const drawerOpen = () => {
    const drawer = document.getElementById('sideDrawer');
    drawer.classList.remove('-translate-x-full');
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        myStore({
          prams: {
            page: 1,
            type: 'accounts',
            user_id: userDetails.id,
          },
        })
      );
    }
    dispatch(
      categories({
        prams: { parent: 0, type: 'listings' },
      })
    );
  }, []);

  //Notifications Effect
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        getNotifications({
          page: 1,
        })
      );
    }
  }, [router]);

  return (
    <>
      <div className="hidden md:block  ">
        {showUserMenus ? (
          <div className=" w-[100vw] h-[100vh]   top-0 z-[60] fixed    bg-transparent    opacity-100" />
        ) : (
          ''
        )}
        <div
          className={`   min-h-[70px]  shadow-c-sm   bg-header top-0    overflow-x-hidden relative `}
          id="header_section"
        >
          <div
            className={` relative max-w-[var(--headerStylesMaxWidth)] px-[var(--headerStylesPaddingX)] mx-auto`}
          >
            <div
              className="flex pt-[5px] min-h-[50px] w-full "
              style={{
                height: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height || 50}px`,
              }}
            >
              <div
                className="  absolute left-0 right-0    flex   px-[var(--headerStylesPaddingX)]  "
                style={{
                  minHeight: `${
                    TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ?? 50
                  }px`,
                  justifyContent: 'var(--headerLogoAlignment)',
                }}
              >
                {logo && (
                  <Link href="/" passHref={true}>
                    <a className=" h-auto flex  flex-col items-start   relative cursor-pointer ">
                      <img
                        src={logo}
                        style={{
                          maxHeight: `${
                            TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ?? 50
                          }px`,
                          width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                          objectFit:
                            TYPE_CONSTANT.GENERAL_CONFIGS?.logo_object_fit ??
                            'contain',
                        }}
                        alt="logo"
                      />
                      {TYPE_CONSTANT.GENERAL_CONFIGS?.tagline?.text && (
                        <TagLine />
                      )}
                    </a>
                  </Link>
                )}
              </div>
              <div className=" z-[100] ltr:ml-auto rtl:mr-auto my-auto">
                <div
                  className=" flex items-center justify-between gap-2"
                  id="header_nav_items"
                >
                  {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
                    <div className=" hidden lg:block  ">
                      <Add_listing_button
                        title={Menubar_text.more.header_sell_btn_title}
                      />
                    </div>
                  )}
                  <Cart_button />
                  {
                    <div className="  " id="header_language_switch_button">
                      <Notifications3 id_name="header_language_switch_button" />
                    </div>
                  }
                  {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                    <div
                      className="hidden lg:block   placeholder:"
                      id="header_store_button"
                    >
                      <StoreButton />
                    </div>
                  )}
                  <div className="   " id="header_wish_list_button">
                    <WishListButton />
                  </div>

                  <Language_switch />

                  <div>
                    <HeaderProfile2
                      showUserMenus={showUserMenus}
                      setShowUserMenus={setShowUserMenus}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 pb-[2px] flex justify-between items-center gap-4">
              <div className="flex-grow">
                <HeaderCategories />
              </div>
              <div className="flex-none">
                <SearchBox />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" md:hidden  bg-header shadow-c-sm ">
        {isDrawerOpen ? (
          <div className="  top-0 z-40 fixed  h-full w-full bg-black   opacity-30" />
        ) : (
          ''
        )}
        {showUserMenus ? (
          <div className=" w-[100vw] h-[100vh]   top-0 z-[60] fixed   bg-transparent    opacity-100" />
        ) : (
          ''
        )}

        <div className="   max-w-[var(--headerStylesMaxWidth)] px-[var(--headerStylesPaddingX)] mx-auto">
          <div className="flex justify-between items-center py-[10px] gap-2  relative">
            <div className="   flex items-center">
              <button className="outline-none" onClick={drawerOpen}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 ltr:mr-[13px] rtl:ml-[13px] cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              {logo && (
                <Link href="/" passHref={true}>
                  <a className="flex flex-col">
                    <img
                      src={logo}
                      style={{
                        maxHeight: `${
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ?? 50
                        }px`,
                        width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                        objectFit:
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_object_fit ??
                          'contain',
                      }}
                      alt="logo"
                    />
                    {TYPE_CONSTANT.GENERAL_CONFIGS?.tagline?.text && (
                      <TagLine />
                    )}
                  </a>
                </Link>
              )}
            </div>
            <div
              className=" flex-none flex items-center justify-between gap-1.5"
              id={'mobile_header_nav_items'}
            >
              {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
                <div className="    ">
                  <Add_listing_button
                    title={Menubar_text.more.header_sell_btn_title}
                  />
                </div>
              )}

              {/* Cart Button */}
              {/* <Cart_button /> */}

              {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                <div className=" hidden xs:block   " d="header_store_button">
                  <StoreButton />
                </div>
              )}
              <div className=" hidden xs:block   ">
                <WishListButton />
              </div>

              <div className="relative">
                <HeaderProfile2
                  showUserMenus={showUserMenus}
                  setShowUserMenus={setShowUserMenus}
                  // dropdownRef={
                  // 	dropdownRef
                  // }
                />
              </div>
            </div>
          </div>
          {/* <div className="pb-[25px]">
            <SearchBox />
          </div> */}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            closeDrawer();
          }}
        >
          <button
            onClick={closeDrawer}
            className={[
              ' absolute  top-0  z-50 bg-gray-50 p-2 text-primary ',
              isDrawerOpen
                ? 'ltr:left-[245px]  rtl:right-[245px]  transition  ease-in-out  duration-700    '
                : 'ltr:left-0  rtl:right-0 transform  transition ltr:-translate-x-full  rtl:translate-x-full    ease-in-out  duration-700',
            ].join(' ')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div
            id="sideDrawer"
            className={
              isDrawerOpen
                ? 'z-50 bg-gray-50  w-[245px] fixed inset-y-0 ltr:left-0  rtl:right-0 transform  transition  ease-in-out  duration-500 overflow-y-scroll'
                : '  order-9 bg-gray-50  w-[245px] fixed inset-y-0 ltr:left-0 rtl:right-0  transform  transition ltr:-translate-x-full  rtl:translate-x-full  ease-in-out  duration-500'
            }
          >
            <div className="">{<Drawer />}</div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default React.memo(Header3);
