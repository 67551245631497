import Cookies from 'js-cookie';
import { Menubar_text } from '../../constant/Translation/Heading';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { logout } from '../../store/feature/authSlice';
import {
  invite_friend,
  plus_icon,
  privacy_icon,
  store_icon,
} from '../Shared/Constant/Icons/AllIcons';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';

export const customer_menus = (
  Link,
  userDetails,
  MARKETPLACE_MODULES,
  general_configs,
  router,
  dispatch
) => {
  return (
    <div>
      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
        <p className=" text-base  text-primary font-semibold  transition duration-500 px-[10px] sm:px-[25px] mb-1">
          {Menubar_text.more.customer}
        </p>
      )}
      <p className="  text-[#6e686e] font-semibold  transition duration-500 px-[10px] sm:px-[25px] my-1">{` ${Menubar_text.more.welcome}  ${userDetails?.first_name} ${userDetails?.last_name}`}</p>

      <Link href="/edit-profile" passHref={true}>
        <a className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6   ltr:mr-3 rtl:ml-3  ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          <span className=" text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
            {Menubar_text.more.profile}
          </span>
        </a>
      </Link>

      {MARKETPLACE_MODULES === 1 && (
        <Link href="/checkout" passHref={true}>
          <div className="   flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6  ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <p className="  text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
              {Menubar_text.more.my_cart}
            </p>
          </div>
        </Link>
      )}

      {MARKETPLACE_MODULES !== null && (
        <Link href="/orders" passHref={true}>
          {MARKETPLACE_MODULES === 1 ? (
            <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6  ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e] mt-[2px]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 hover:text-primary">
                {Menubar_text.more.my_orders}
              </span>
            </div>
          ) : (
            <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e] mt-[2px]"
                width="24"
                height="20"
                viewBox="0 0 20 14"
                fill="none"
              >
                <path
                  d="M7 5C6.73478 5 6.48043 5.10536 6.29289 5.29289C6.10536 5.48043 6 5.73478 6 6V8C6 8.26522 6.10536 8.51957 6.29289 8.70711C6.48043 8.89464 6.73478 9 7 9C7.26522 9 7.51957 8.89464 7.70711 8.70711C7.89464 8.51957 8 8.26522 8 8V6C8 5.73478 7.89464 5.48043 7.70711 5.29289C7.51957 5.10536 7.26522 5 7 5ZM19 6C19.2652 6 19.5196 5.89464 19.7071 5.70711C19.8946 5.51957 20 5.26522 20 5V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6C1.26522 6 1.51957 6.10536 1.70711 6.29289C1.89464 6.48043 2 6.73478 2 7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8C18.7348 8 18.4804 7.89464 18.2929 7.70711C18.1054 7.51957 18 7.26522 18 7C18 6.73478 18.1054 6.48043 18.2929 6.29289C18.4804 6.10536 18.7348 6 19 6ZM18 4.18C17.4208 4.3902 16.9205 4.77363 16.5668 5.27816C16.2132 5.7827 16.0235 6.38388 16.0235 7C16.0235 7.61612 16.2132 8.2173 16.5668 8.72184C16.9205 9.22637 17.4208 9.6098 18 9.82V12H8C8 11.7348 7.89464 11.4804 7.70711 11.2929C7.51957 11.1054 7.26522 11 7 11C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12H2V9.82C2.57915 9.6098 3.07954 9.22637 3.43316 8.72184C3.78678 8.2173 3.97648 7.61612 3.97648 7C3.97648 6.38388 3.78678 5.7827 3.43316 5.27816C3.07954 4.77363 2.57915 4.3902 2 4.18V2H6C6 2.26522 6.10536 2.51957 6.29289 2.70711C6.48043 2.89464 6.73478 3 7 3C7.26522 3 7.51957 2.89464 7.70711 2.70711C7.89464 2.51957 8 2.26522 8 2H18V4.18Z"
                  fill="#6e686e"
                />
              </svg>
              <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 hover:text-primary">
                {Menubar_text.more.my_orders}
              </span>
            </div>
          )}
        </Link>
      )}
      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
        !TYPE_CONSTANT.ACCOUNTS_CONFIGS?.is_hide_social_feed && (
          <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#6e686e]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
              />
            </svg>
            <Link href="/social_feed" passHref={true}>
              <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary    ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
                {Menubar_text.more.social_feed}
              </a>
            </Link>
          </div>
        )}
      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS == 1 && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <Link href="/a/following" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.more_stores_i_follow}
            </a>
          </Link>
        </div>
      )}
      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS == 1 && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <Link href="/a/blocked" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.more_stored_i_blocked}
            </a>
          </Link>
        </div>
      )}

      <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6 text-[#6e686e]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>

        <Link href="/addresses" passHref={true}>
          <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
            {Menubar_text.more.my_address}
          </a>
        </Link>
      </div>

      {general_configs?.terms_url && (
        <div className="   flex  sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6   ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
          <p
            onClick={() => window.open(general_configs?.terms_url)}
            className="  text-sm  text-[#222222]  text-left font-semibold  transition duration-500 hover:text-primary"
          >
            {Menubar_text.more.terms_condition}
          </p>
        </div>
      )}
      {general_configs?.privacy_policy_url && (
        <div className=" flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          {privacy_icon}
          <p
            onClick={() => window.open(general_configs?.privacy_policy_url)}
            className=" text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5"
          >
            {Menubar_text.more.privacy_policy}
          </p>
        </div>
      )}
      {general_configs?.invite_friends_collection_enabled && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group ">
          {invite_friend}
          <Link href="/invite" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.tell_a_friend}
            </a>
          </Link>
        </div>
      )}
      {TYPE_CONSTANT.GENERAL_CONFIGS?.enable_feedback && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6 text-[#6e686e]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
            />
          </svg>
          <Link href="/feedback" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.feedback}
            </a>
          </Link>
        </div>
      )}

      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS == 1 &&
        !Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) && (
          <Link
            href={{
              pathname: '/a/create-account',
            }}
          >
            <a>
              <div className=" flex  sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
                <div>{store_icon}</div>
                <p className="ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5 text-sm  text-left text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
                  {Menubar_text.more.create_account}
                </p>
              </div>
            </a>
          </Link>
        )}

      <div
        className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group"
        onClick={() => {
          dispatch(logout({ router }));
          window.location.reload();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6    text-[#6e686e]"
          fill="none"
          viewBox="-2 0 24 24 "
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 group-hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
          {Menubar_text.more.logging_out}
        </span>
      </div>
    </div>
  );
};

export const account_menus = (Link, router, my_stores) => {
  return (
    <div>
      <p className=" text-base  text-primary font-semibold  transition duration-500 px-[10px] sm:px-[25px] mb-1">
        {Menubar_text.more.business}
      </p>
      {/* <div className=" rtl:hidden w-[15px] h-[15px] bg-[#fff] absolute   right-0  transform rotate-45  -top-2    ltr:mr-3   rtl:ml-3 ltr:md:mr-5 rtl:md:ml-5 border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" /> */}
      <Link href="/a/my-account" passHref={true}>
        <a className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <div>{store_icon}</div>
          <span className="ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5 text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
            {Menubar_text.more.my_store}
          </span>
        </a>
      </Link>

      <div className=" flex sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6   ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e] mt-[2px]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
          />
        </svg>
        <Link
          href={{
            pathname: '/a/orders',
            query: { account_id: my_stores[0].id },
          }}
        >
          <a
            // onClick={() =>
            //   router.push({
            //     pathname: '/a/orders',
            //     query: { account_id: my_stores[0].id, page: 1 },
            //   })
            // }
            className=" block text-sm text-left  text-[#222222]  font-semibold  transition duration-500 hover:text-primary"
          >
            {Menubar_text.more.my_store_orders}
          </a>
        </Link>
      </div>
      <div className=" flex sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke-width="2"
          className="h-6 w-6    ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e] mt-[2px]"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
          />
        </svg>

        <Link
          href={{
            pathname: '/transactions',
            query: { account_id: my_stores[0].id },
          }}
        >
          <a className=" block text-sm text-left  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
            {Menubar_text.more.my_sales}
          </a>
        </Link>
      </div>

      {my_stores?.length > 0 &&
        TYPE_CONSTANT.PAYMENT_CONFIGS?.stripe_enabled && (
          <div className=" flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6    ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e] mt-[2px]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <Link href="/payout" passHref={true}>
              <a className=" text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
                {Menubar_text.more.payments}
              </a>
            </Link>
          </div>
        )}
      {my_stores?.length > 0 && (
        <Add_listing_button
          title={Menubar_text.more.add_new_listing}
          btn_styles={
            'w-full flex  sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group'
          }
          icon={plus_icon}
          text_styles="ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5 text-sm  text-left text-[#222222]  font-semibold  transition duration-500 hover:text-primary"
        />
      )}
      {/* {my_stores?.length > 0 && (
        <Link
          href={{
            pathname: '/c/add_article',
            query: { account_id: my_stores[0].id },
          }}
        >
          <a>
            <div className=" flex  sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
              <div>{plus_icon}</div>
              <p className="ml-3  sm:ml-5 text-sm  text-left text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
                Add Content
              </p>
            </div>
          </a>
        </Link>
      )} */}
    </div>
  );
};

export const custom_customer_menus = (
  Link,
  userDetails,
  MARKETPLACE_MODULES,
  general_configs,
  router,
  dispatch
) => {
  return (
    <div>
      <Link href="/edit-profile" passHref={true}>
        <a className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6   ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          <span className=" text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
            {Menubar_text.more.profile}
          </span>
        </a>
      </Link>
      {/* {MARKETPLACE_MODULES !== null && (
        <Link href="/orders" passHref={true}>
          {MARKETPLACE_MODULES === 1 ? order : booking}
        </Link>
      )} */}

      {MARKETPLACE_MODULES === 1 && (
        <Link href="/checkout" passHref={true}>
          <div className="   flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6   ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <p className="  text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary">
              {Menubar_text.more.my_cart}
            </p>
          </div>
        </Link>
      )}
      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
        !TYPE_CONSTANT.ACCOUNTS_CONFIGS?.is_hide_social_feed && (
          <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#6e686e]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
              />
            </svg>
            <Link href="/social_feed" passHref={true}>
              <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
                {Menubar_text.more.social_feed}
              </a>
            </Link>
          </div>
        )}
      {!TYPE_CONSTANT.ACCOUNTS_CONFIGS?.is_hide_following_accounts_list && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <Link href="/a/following" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.more_stores_i_follow}
            </a>
          </Link>
        </div>
      )}
      {!TYPE_CONSTANT.ACCOUNTS_CONFIGS?.is_hide_blocked_accounts_list && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-[#6e686e]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
          <Link href="/a/blocked" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.more_stored_i_blocked}
            </a>
          </Link>
        </div>
      )}

      <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6 text-[#6e686e]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>

        <Link href="/addresses" passHref={true}>
          <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
            {Menubar_text.more.my_address}
          </a>
        </Link>
      </div>
      <div className="   flex  sm:items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6   ltr:mr-3   rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5 text-[#6e686e]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          />
        </svg>
        <p
          onClick={() => window.open(general_configs?.terms_url)}
          className="  text-sm  text-[#222222]  text-left font-semibold  transition duration-500 hover:text-primary"
        >
          {Menubar_text.more.terms_condition}
        </p>
      </div>
      <div className=" flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
        {privacy_icon}
        <p
          onClick={() => window.open(general_configs?.privacy_policy_url)}
          className=" text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5"
        >
          {Menubar_text.more.privacy_policy}
        </p>
      </div>
      {general_configs?.invite_friends_collection_enabled && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group ">
          {invite_friend}
          <Link href="/invite" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.tell_a_friend}
            </a>
          </Link>
        </div>
      )}

      {TYPE_CONSTANT.GENERAL_CONFIGS?.enable_feedback && (
        <div className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6 text-[#6e686e]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
            />
          </svg>
          <Link href="/feedback" passHref={true}>
            <a className=" block text-sm  text-[#222222]  font-semibold  transition duration-500 hover:text-primary ltr:ml-3   rtl:mr-3 ltr:sm:ml-5 rtl:sm:mr-5">
              {Menubar_text.more.feedback}
            </a>
          </Link>
        </div>
      )}

      <div
        className="flex items-center cursor-pointer w-auto  px-[10px] sm:px-[25px] py-2  hover:bg-[#f2f4f4] group"
        onClick={() => {
          dispatch(logout({ router }));
          window.location.reload();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 ml-1   ltr:mr-4   rtl:ml-4   text-[#6e686e]"
          fill="none"
          viewBox="0 0 24 24 "
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 group-hover:text-primary">
          {Menubar_text.more.logging_out}
        </span>
      </div>
    </div>
  );
};
