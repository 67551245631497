import {
  invite_friend,
  invite_friend_2,
  plus_icon_2,
  privacy_icon,
  privacy_icon_2,
  store_icon_2,
} from '../Shared/Constant/Icons/AllIcons';

export const all_menus_2 = ({ store_details, Menubar_text, TYPE_CONSTANT }) => {
  return [
    {
      id: 1,
      type: 'customer',
      title: Menubar_text.more.profile,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      ),
      endpoint: '/edit-profile',
      is_show: true,
    },

    // {
    //   id: 8,
    //   type: 'customer',
    //   title: Menubar_text.more.terms_condition,
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       className="h-6 w-6    "
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="2"
    //         d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
    //       />
    //     </svg>
    //   ),
    //   endpoint: TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url,
    //   is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url ? true : false,
    // },
    // // {
    // //   id: 9,
    // //   type: 'customer',
    // //   title: Menubar_text.more.privacy_policy,
    // //   icon: privacy_icon_2,
    // //   endpoint: TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy,
    // //   is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy ? true : false,
    // // },
    // {
    //   id: 10,
    //   type: 'customer',
    //   title: Menubar_text.more.tell_a_friend,
    //   icon: invite_friend_2,
    //   endpoint: '/invite',
    //   is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.invite_friends_collection_enabled
    //     ? true
    //     : false,
    // },

    {
      id: 12,
      type: 'business',
      title: Menubar_text.more.my_store,
      icon: store_icon_2,
      endpoint: '/a/my-account',
      is_show: true,
    },
    // {
    //   id: 13,
    //   type: 'business',
    //   title: Menubar_text.more.my_store_orders,
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       className="h-6 w-6 "
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="2"
    //         d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
    //       />
    //     </svg>
    //   ),
    //   endpoint: `/a/orders?account_id=${store_details?.id}`,
    //   is_show: true,
    // },
    // {
    //   id: 14,
    //   type: 'business',
    //   title: Menubar_text.more.my_sales,
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       stroke-width="2"
    //       className="h-6 w-6     "
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
    //       />
    //     </svg>
    //   ),
    //   endpoint: `/transactions?account_id=${store_details?.id}`,
    //   is_show: true,
    // },
    // {
    //   id: 15,
    //   type: 'business',
    //   title: Menubar_text.more.payments,
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       className="h-6 w-6   "
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke="currentColor"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         strokeWidth="2"
    //         d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
    //       />
    //     </svg>
    //   ),
    //   endpoint: `/payout`,
    //   is_show: TYPE_CONSTANT.PAYMENT_CONFIGS?.stripe_enabled ? true : false,
    // },
    {
      id: 16,
      type: 'business',
      title: Menubar_text.more.add_new_listing,
      icon: plus_icon_2,
      endpoint: `/a/add-listing?account_id=${store_details?.id}`,
      is_show: store_details ? true : false,
    },
    {
      id: 11,
      type: 'customer',
      title: Menubar_text.more.feedback,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6  "
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
        </svg>
      ),
      endpoint: '/feedback',
      is_show: TYPE_CONSTANT.GENERAL_CONFIGS?.enable_feedback ? true : false,
    },
  ];
};
