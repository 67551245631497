import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const HamburgerButton = ({ drawerOpen, data, styles }) => {
  console.log(data);
  return (
    <button
      style={get_dynamic_styles(styles?.button_styles)}
      className="outline-none"
      onClick={drawerOpen}
    >
      {data?.image ? (
        <img
          className="w-full h-full"
          style={get_dynamic_styles(styles?.image_styles)}
          src={data?.image}
          alt=""
        />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-full w-full cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      )}
    </button>
  );
};

export default HamburgerButton;
