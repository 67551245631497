import axios from 'axios';
import React, { useState } from 'react';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import {
  delete_icon,
  download_icon,
  file_icon,
} from '../../Shared/Constant/Icons/AllIcons';

const DigitalProductDownload = ({ order_details }) => {
  const orderDetails = order_details?.order_details;
  const [isLoading, setIsLoading] = useState(null);

  const downloadAsset = async (items, key) => {
    setIsLoading({ [key]: true });
    axios
      .post('/api/getS3SignedURL', {
        data: {
          urls: items.map((item) => {
            return { url: item.file_url, downloadable: true };
          }),
        },
      })
      .then((res) => {
        setIsLoading(null);

        window.open(res.data.result[0]);
      })
      .catch((error) => {
        setIsLoading(null);
      });
  };

  return (
    <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40 flex flex-col gap-3">
      <div className="flex  ">
        <p className=" text-lg text-black font-semibold   ">
          {Orders_text?.orderdetail.download_assets}
        </p>
      </div>
      <div className="flex flex-col gap-6">
        {/* Contents list */}
        {orderDetails?.map((order) => {
          const { content, title, description } =
            order.digital_contents?.length > 0
              ? order?.digital_contents[0]
              : {};
          return (
            <div className="">
              {content?.length > 0 ? (
                <div className="flex flex-col gap-6 ">
                  {content?.map((item, index) => {
                    return (
                      <div
                        className="w-full flex items-center justify-start gap-3 rounded-card shadow-sm border border-primary p-2 "
                        key={`${item?.file_name}-${index}`}
                      >
                        <span className=" flex -none text-primary">
                          {file_icon}
                        </span>
                        <div className=" flex-grow flex flex-col gap-1">
                          <p className=" flex-grow text-lg text-gray-800 font-medium">
                            {item.file_name}
                          </p>
                          <p className="text-sm font-normal text-gray-600">
                            {item.size}
                          </p>
                        </div>
                        <button
                          className="text-primary hover:text-success  flex-none "
                          onClick={() =>
                            downloadAsset([item], `${item?.file_name}-${index}`)
                          }
                        >
                          {isLoading?.[`${item?.file_name}-${index}`] ? (
                            <svg
                              className="animate-spin h-5 w-5   text-primary"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            download_icon
                          )}
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DigitalProductDownload;
