/* eslint-disable react/prop-types */
import React from 'react';
import Head from 'next/head';
import Header3 from '../../components/Header/Header3';
import Footer from '../../components/Footer/Footer';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <>
      <div className="  ">
        <div className="  sticky  top-0 z-50 " id={'template-main-header'}>
          <Header3 />
        </div>
        <div
          className="bg-body min-h-screen  overflow-x-hidden "
          id="main-components-bods"
        >
          <div className=" max-w-[var(--bodyStylesMaxWidth)] mx-auto  px-[var(--bodyStylesPaddingX)]  py-[var(--bodyStylesPaddingY)]">
            {children}
          </div>
        </div>
        <div id="footer" className="bg-footer min-h-[200px]   left-0 bottom-0 ">
          <div className=" max-w-[var(--bodyStylesMaxWidth)] mx-auto  px-[var(--bodyStylesPaddingX)] ">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
