/* eslint-disable react/prop-types */
import React from 'react';
import DynamicHeader from '../../components/Header/DynamicHeader';
import { useRouter } from 'next/router';
import DynamicFooter from '../../components/Footer/DynamicFooter';
import GlobalSidebar from '../../components/Shared/Modal/GlobalSidebar';
import DynamicBottomTabbar from '../../components/BottomTab/DynamicBottomTabbar';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const MainLayout = ({ children }) => {
  const router = useRouter();
  const current_path_name = router.pathname;
  const different_styles_path = [
    '/',
    '/checkout',
    '/l/[slug]',
    '/b/[slug]',
    '/p/[slug]',
  ];
  const DynamicBottombarStyle =
    TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_bottom_tabbar;

  return (
    <>
      <DynamicHeader />
      <div
        className="bg-body min-h-[90VH]  overflow-x-hidden "
        id="main-components-bods"
      >
        <div
          className={[
            !different_styles_path.includes(current_path_name) &&
              ' max-w-[var(--bodyStylesMaxWidth)] mx-auto  px-[var(--bodyStylesPaddingX)]  pt-3 py-[var(--bodyStylesPaddingY)]',
          ].join(' ')}
        >
          {children}
        </div>
      </div>
      {DynamicBottombarStyle?.schema?.length > 0 && <DynamicBottomTabbar />}
      <DynamicFooter />
      <GlobalSidebar />
    </>
  );
};

export default MainLayout;
