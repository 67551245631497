/* eslint-disable react/prop-types */
import React from 'react';
import { Address_text } from '../../../constant/Translation/Address';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import OrderDetails from '../../../pages/orders/[id]';

const AddressBox = ({ order_details }) => {
  const type = order_details?.shipping_method.type;
  let address;
  if (type === 'pickup') {
    address = order_details?.pickup_address;
  } else {
    address = order_details?.shipping_address;
  }
  return (
    <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
      <div className="flex  ">
        <p className=" text-lg text-black font-semibold   ">
          {order_details?.shipping_method.name}{' '}
          {Address_text.addresslist.address_title}
        </p>
      </div>
      <div className="mt-4">
        {Object?.keys(address)?.length > 0 ? (
          <>
            {address?.name && (
              <div className=" flex justify-start items-start py-1  ">
                <p className=" flex-none text-sm text-black font-semibold  w-24 ">
                  {Address_text.address.name} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.name}
                </p>
              </div>
            )}
            {address?.house_number && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24 ">
                  {Address_text.address.house_number} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.house_number}
                </p>
              </div>
            )}
            {address?.address_line_1 && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24 ">
                  {Address_text.address.address_one} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.address_line_1}
                </p>
              </div>
            )}
            {address?.address_line_2 && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24 ">
                  {Address_text.address.address_two} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.address_line_2}
                </p>
              </div>
            )}
            {address?.city && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24 ">
                  {Address_text.address.city} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {address?.city}
                </p>
              </div>
            )}
            {address?.country && (
              <div className=" flex justify-start items-start py-1  ">
                <p className=" flex-none text-sm text-black font-semibold  w-24">
                  {Address_text.address.country} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.country}
                </p>
              </div>
            )}
            {address?.post_code && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24">
                  {Address_text.address.zipcode} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.post_code}
                </p>
              </div>
            )}
            {address?.formatted_address && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24">
                  {Address_text.address.formatted_address} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.formatted_address}
                </p>
              </div>
            )}
            {address?.phone_number && (
              <div className=" flex justify-start items-start py-1  ">
                <p className="flex-none text-sm text-black font-semibold  w-24">
                  {Address_text.address.phone} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                  {address.phone_number}
                </p>
              </div>
            )}
          </>
        ) : (
          Address_text.addresslist.no_address_found
        )}
      </div>
    </div>
  );
};

export default AddressBox;
