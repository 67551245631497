import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../../store/feature/categorySlice';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { angle_down } from '../../Shared/Constant/Icons/AllIcons';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { slug as SlugChecker } from 'github-slugger';

const FooterCategories = ({ data, styles }) => {
  const router = useRouter();

  const [isSeeAllCategories, setIsSeeAllCategories] = useState(false);

  const { all_categories, all_account_categories } =
    useSelector(categorySelector);

  const selected_type_categories =
    data?.type === 'listings' ? all_categories : all_account_categories;

  return (
    <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
      {selected_type_categories?.map((item, index, array) => {
        if (index < 3) {
          return (
            <div className="" key={Math.random()}>
              {' '}
              <Link
                href={{
                  pathname: `/lc/[slug]`,
                  query: {
                    slug: item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`,
                  },
                }}
              >
                <a style={get_dynamic_styles(styles?.category)}>
                  {item.name.length > 20
                    ? item.name.substring(0, 19) + '.'
                    : item.name}
                </a>
              </Link>
            </div>
          );
        }
        if (array?.length > 3 && index === 3) {
          return (
            <div className="" key={Math.random()}>
              <button
                className="   flex    items-center  gap-4   "
                onClick={() => setIsSeeAllCategories(!isSeeAllCategories)}
                style={get_dynamic_styles(styles?.category)}
              >
                <span className="  text-primary  ">
                  {Menubar_text.more.see_all_categories}
                </span>
                <span
                  className={[
                    '  text-primary ',
                    isSeeAllCategories && 'transform rotate-180',
                  ].join(' ')}
                >
                  {angle_down}
                </span>
              </button>
            </div>
          );
        }
      })}

      {isSeeAllCategories &&
        selected_type_categories?.map((item, index, array) => {
          if (index >= 4) {
            return (
              <div className="" key={Math.random()}>
                <Link
                  href={{
                    pathname: `/lc/[slug]`,
                    query: {
                      slug: item.slug
                        ? item.slug
                        : `${item?.id}-${SlugChecker(item.name)}`,
                    },
                  }}
                >
                  <a style={get_dynamic_styles(styles?.category)}>
                    {item.name.length > 20
                      ? item.name.substring(0, 19) + '.'
                      : item.name}
                  </a>
                </Link>
              </div>
            );
          }
        })}
    </div>
  );
};

export default FooterCategories;
