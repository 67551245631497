/* eslint-disable react/prop-types */
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { useRouter } from 'next/dist/client/router';

import { myStore, storeSelector } from '../../store/feature/storeSlice';
import { account_menus, customer_menus } from './desktopMenu';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menubar_text } from '../../constant/Translation/Heading';
import { sign_in_icon } from '../Shared/Constant/Icons/AllIcons';
import { getCookie } from 'cookies-next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useReducer } from 'react';

const HeaderProfile2 = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  // console.log(my_stores);

  const [openMenu, setOpenMenu] = useState(false);

  const { profile_pic, login } = useSelector(authSelector);
  const { my_stores } = useSelector(storeSelector);

  const open_menu_ref = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!open_menu_ref.current) return;
      if (
        !openMenu ||
        open_menu_ref.current.contains(target) ||
        open_menu_ref.current.contains(target)
      )
        return;
      setOpenMenu(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  return (
    <>
      <div className="bg-transparent flex rtl:flex-row-reverse items-center  cursor-pointer   group relative ">
        {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) ? (
          <div
            className="flex  items-center rtl:flex-row-reverse"
            id="profile_section"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {profile_pic !== undefined && profile_pic !== '' ? (
              <div className=" w-10 h-10 relative rounded-full overflow-hidden">
                <img
                  alt="profile_pic"
                  src={profile_pic}
                  className="h-full w-full object-cover object-left-top"
                />
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" h-10 w-10  md:h-10 md:w-10 text-[#6e686e]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
            {/* <p className=" hidden lg:block text-base font-medium text-primary  lg:ml-2">
              {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) ? first_name : 'Guest'}
            </p> */}
            <svg
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=" w-[12px] h-[8px] ml-[5px] "
            >
              <path
                d="M11.0002 1.17019C10.8128 0.983936 10.5594 0.879395 10.2952 0.879395C10.031 0.879395 9.77756 0.983936 9.59019 1.17019L6.00019 4.71019L2.46019 1.17019C2.27283 0.983936 2.01938 0.879395 1.75519 0.879395C1.49101 0.879395 1.23756 0.983936 1.05019 1.17019C0.956464 1.26315 0.88207 1.37375 0.831301 1.49561C0.780533 1.61747 0.754395 1.74818 0.754395 1.88019C0.754395 2.0122 0.780533 2.1429 0.831301 2.26476C0.88207 2.38662 0.956464 2.49722 1.05019 2.59019L5.29019 6.83019C5.38316 6.92392 5.49376 6.99831 5.61562 7.04908C5.73747 7.09985 5.86818 7.12599 6.00019 7.12599C6.1322 7.12599 6.26291 7.09985 6.38477 7.04908C6.50663 6.99831 6.61723 6.92392 6.71019 6.83019L11.0002 2.59019C11.0939 2.49722 11.1683 2.38662 11.2191 2.26476C11.2699 2.1429 11.296 2.0122 11.296 1.88019C11.296 1.74818 11.2699 1.61747 11.2191 1.49561C11.1683 1.37375 11.0939 1.26315 11.0002 1.17019Z"
                fill="#6e686e"
              />
            </svg>
          </div>
        ) : (
          <div>
            <button
              className="btn-primary-fill"
              onClick={() => router.push(`/sign-in?to=${router.asPath}`)}
            >
              <span className=" hidden  ms:block "> {sign_in_icon}</span>
              <span className=" ltr:ms:ml-[6px] rtl:ms:mr-1.5">
                {' '}
                {Menubar_text.more.signin_signup}
              </span>
            </button>
          </div>
        )}

        <div
          id="profileMenus"
          ref={open_menu_ref}
          className={[
            `  md:group-hover:block  fixed top-0 ltr:right-0 xs:ltr:right-[calc(max(var(--headerStylesPaddingX),((100VW)-(var(--headerStylesMaxWidth)))/2))]  rtl:left-0 xs:rtl:left-[calc(max(var(--headerStylesPaddingX),((100VW)-(var(--headerStylesMaxWidth)))/2))] z-[60]   pt-1 md:pt-4   transition duration-1000  `,
            openMenu ? 'block' : 'hidden',
          ].join(' ')}
          style={{
            marginTop: `${
              (window?.innerWidth > 768
                ? document.getElementById('header_nav_items')?.offsetTop
                : document.getElementById('mobile_header_nav_items')
                    ?.offsetTop) + 40
            }px`,
          }}
        >
          {login && (
            <div
              className={[
                'bg-[#fff] rounded-card  mt-[10px] md:mt-0      pt-[20px] pb-[15px]   min-h-[100px]  border border-[rgba(250, 250, 250, 0.93)]  shadow-sm  relative',
              ]}
            >
              <div className=" hidden md:block rtl:md:hidden w-[15px] h-[15px] bg-[#fff] absolute   right-0  transform rotate-45  -top-2  ltr:mr-4 rtl:ml-4    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" />
              <div className=" max-h-[70VH] overflow-y-auto">
                {login && (
                  <>
                    {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
                    my_stores?.length > 0 ? (
                      <div className="grid   grid-cols-2 ms:grid-cols-[200px,200px] sm:grid-cols-[250px,250px]">
                        {customer_menus(
                          Link,
                          userDetails,
                          TYPE_CONSTANT.MARKETPLACE_MODULES,
                          TYPE_CONSTANT.GENERAL_CONFIGS,
                          router,
                          dispatch
                        )}
                        {account_menus(Link, router, my_stores)}
                      </div>
                    ) : (
                      <div className="grid grid-cols-[250px]">
                        {customer_menus(
                          Link,
                          userDetails,
                          TYPE_CONSTANT.MARKETPLACE_MODULES,
                          TYPE_CONSTANT.GENERAL_CONFIGS,
                          router,
                          dispatch
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderProfile2;
