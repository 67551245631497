/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  changeOrderStatus,
  changeShipmentStatus,
  get_order_details,
  orderSelector,
} from '../../../store/feature/orderSlice';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import {
  ShipmentStatus,
  changeStatus,
  currentShipmentStatus,
  orderStatus,
} from '../../Shared/Constant/Status';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import OrderTimeline from '../OrderTimeline/OrderTimeline';
import AddressBox3 from '../AddressBox/AddressBox3';
import Modal from '../../Shared/Modal/Modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';
import OrderTracking from '../OrderSummary/OrderTracking';
import OrderTracking2 from '../OrderSummary/OrderTracking2';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const ShipmentDetails2 = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const dispatch = useDispatch();

  //
  const [showTrackingShipmentForm, setShowTrackingShipmentForm] =
    useState(false);
  const [selected_shipment, setSelectedShipment] = useState(null);
  // Status Changing
  const status_change = (status, order_details) => {
    if (status) {
      dispatch(
        changeOrderStatus({
          id: order_details.id,
          sendData: {
            order: {
              status: Number(status),
            },
          },
        })
      ).then((res) => {
        if (!res.payload.code) {
          setShowSuccess(true);
          setSuccess_message(
            Orders_text.orderdetail.status_changed_successfully
          );
          dispatch(
            get_order_details({
              id: order_details.id,
            })
          );
        }
      });
    }
  };

  const { isChangeStatusFetching } = useSelector(orderSelector);

  const confirmDelivery = (shipment) => {
    dispatch(
      changeShipmentStatus({
        id: order_details.id,
        shipment_id: shipment.id,
        sendData: {
          shipment: {
            status: 8,
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        setShowSuccess(true);
        setSuccess_message(Orders_text.orderdetail.status_changed_successfully);
        dispatch(
          get_order_details({
            id: order_details.id,
          })
        );
      }
    });
  };

  return (
    <div className="flex flex-col gap-6 ">
      {isChangeStatusFetching && <CustomLoading />}

      {showTrackingShipmentForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowTrackingShipmentForm(false);
              setSelectedShipment(null);
            }}
          >
            <OrderTracking2 shipment={selected_shipment} />
          </OutsideClickHandler>
        </Modal>
      )}

      {order_details?.shipments?.map((shipment, index) => {
        const OrderDetails = shipment?.order_details;
        const is_tax_available = OrderDetails?.find(
          (it) => it?.listing_taxes?.length > 0
        );

        return (
          <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-card      p-4   sm:p-7   border-opacity-40  flex flex-col gap-4 ">
            <div className="flex items-center justify-start  gap-2  flex-wrap ">
              <p className="text-primary text-xl leading-6 font-medium  ">
                {Orders_text.orderdetail.shipment} {index + 1}
              </p>

              {shipment?.account && (
                <p className="text-xl font-medium  ">
                  (
                  {shipment?.fulfilled_by_platform
                    ? Orders_text.orderdetail.items_fullfilled_by_platform
                    : dynamic_translation_set(
                        Orders_text.orderdetail.items_fullfilled_by_account,
                        shipment?.account?.name
                      )}
                  )
                </p>
              )}
            </div>
            <div
              className={[
                'grid   gap-8 lg:gap-14  ',
                order_details?.type !== 'digital'
                  ? '  grid-cols-1 lg:grid-cols-2'
                  : 'grid-cols-1',
              ].join(' ')}
            >
              {/* Items */}
              <div className="  flex flex-col gap-4   ">
                {/* Order Details items  */}
                <div className="flex flex-col gap-2">
                  {/* Header */}
                  <div
                    className={[
                      ' grid   pb-2 border-b-2 border-gray-400 bg-white z-20',
                      is_tax_available
                        ? 'grid-cols-[45%,15%,15%,25%] md:grid-cols-[35%,15%,15%,15%,20%]'
                        : 'grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%]',
                    ].join(' ')}
                  >
                    <p className=" text-sm sm:text-lg text-black font-semibold ">
                      {Orders_text.orderdetail.items_summary}
                    </p>
                    <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.qty}
                    </p>
                    <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.price}
                    </p>

                    {is_tax_available && (
                      <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                        {Orders_text.orderdetail.tax}
                      </p>
                    )}
                    <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                      {Orders_text.orderdetail.total_price}
                    </p>
                  </div>
                  {/* Items */}
                  <div>
                    {OrderDetails?.map((item, index) => {
                      let listing_details = item?.listing;
                      let listing_variant_details = item?.variant
                        ? Object?.keys(item?.variant)?.length > 0
                          ? item?.variant
                          : undefined
                        : undefined;

                      let item_details = listing_variant_details
                        ? listing_variant_details
                        : listing_details;

                      return (
                        <Link
                          key={Math.random()}
                          href={
                            item?.listing?.slug
                              ? `/l/${item?.listing?.slug}`
                              : `/l/${
                                  item?.listing?.id
                                }-${item?.listing?.title.replace(
                                  /[ /?,#=]+/g,
                                  '-'
                                )}?id=true`
                          }
                        >
                          <a
                            className={[
                              '  grid    items-center py-3  cursor-pointer',
                              is_tax_available
                                ? 'grid-cols-[45%,15%,15%,25%] md:grid-cols-[35%,15%,15%,15%,20%]'
                                : 'grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%]',
                              index !== OrderDetails.length - 1 &&
                                'border-b-2 border-gray-400 border-opacity-20',
                            ].join(' ')}
                          >
                            <div
                              className=" flex items-center ltr:pl-1 rtl:pr-1"
                              key={item.id}
                            >
                              {item_details?.images?.length > 0 && (
                                <img
                                  src={getThumbnailImage(
                                    item_details?.images[0]
                                  )}
                                  className="h-auto w-[50px] object-cover object-left-top"
                                  alt="image"
                                  onError={(e) =>
                                    (e.target.src = item_details?.images[0])
                                  }
                                />
                              )}
                              <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                                <p className=" text-sm font-semibold  ">
                                  {listing_details.title}{' '}
                                  {listing_variant_details &&
                                    listing_variant_details?.title &&
                                    `(${listing_variant_details.title})`}
                                </p>
                                {item?.listing?.fulfilled_by_platform &&
                                  TYPE_CONSTANT.SHIPPING_CONFIGS
                                    .fulfillment_by_platform_enabled && (
                                    <img
                                      className=" w-auto h-auto max-w-[80px]"
                                      src={
                                        TYPE_CONSTANT.SHIPPING_CONFIGS
                                          .fulfilled_by_platform_icon_path
                                      }
                                      alt="image"
                                    />
                                  )}
                                {!item?.listing?.fulfilled_by_platform &&
                                  TYPE_CONSTANT.SHIPPING_CONFIGS
                                    .fulfillment_by_platform_enabled && (
                                    <img
                                      className="w-auto h-auto  max-w-[80px]"
                                      src={
                                        TYPE_CONSTANT.SHIPPING_CONFIGS
                                          .fulfilled_by_seller_icon_path
                                      }
                                      alt="image"
                                    />
                                  )}
                              </div>
                            </div>
                            <div>
                              <p className=" text-sm font-semibold  text-center">
                                X {item.quantity}
                              </p>
                            </div>
                            <div className=" hidden md:block">
                              <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                                {item.offer_price.formatted}
                              </p>
                            </div>
                            {item?.listing_taxes?.length > 0 && (
                              <div className=" flex  flex-col">
                                {item?.listing_taxes?.map((tax) => {
                                  return (
                                    <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                                      {tax?.amount?.formatted}
                                    </p>
                                  );
                                })}
                              </div>
                            )}
                            <div>
                              <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                                {item.offer_price.formatted}
                              </p>
                            </div>
                          </a>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="  grid grid-cols-1 md:grid-cols-7  gap-8 lg:gap-14 ">
                {/* Addrees */}
                {shipment && order_details?.type !== 'digital' && (
                  <div className=" col-span-1 md:col-span-4 shadow-md  rounded-card p-2">
                    <AddressBox3
                      shipment={shipment}
                      order_details={order_details}
                    />
                  </div>
                )}

                {/* Timeline */}
                {shipment?.shipment_status_history?.length > 0 &&
                  order_details?.type !== 'digital' && (
                    <div className=" col-span-1 md:col-span-3   shadow-md rounded-card p-2">
                      <OrderTimeline
                        shipment={shipment}
                        order_details={order_details}
                      />
                    </div>
                  )}
              </div>
            </div>

            {/* Status change btn */}
            <div className="flex items-center justify-end gap-3 flex-wrap mt-2">
              <div className="flex items-center gap-2 ">
                {/* <p>{Orders_text.orderlist.order_status} :</p> */}
                <p className="    text-primary font-semibold">
                  {currentShipmentStatus(shipment.status)}
                </p>
              </div>

              {/* status */}
              {shipment.status === 8 && (
                <Link href={`/review/${order_details?.reference_number}`}>
                  <a className="btn-primary-fill ">
                    {Orders_text.orderdetail.review_product}
                  </a>
                </Link>
              )}

              {/*  */}
              {shipment?.next_status?.includes(8) && (
                <button
                  className="btn-primary-fill"
                  onClick={() => confirmDelivery(shipment)}
                >
                  {ShipmentStatus(8)}
                </button>
              )}
              {/*  */}
              {order_details?.type !== 'digital' && (
                <button
                  className=" btn-primary-outline  "
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTrackingShipmentForm(true);
                    setSelectedShipment(shipment);
                  }}
                >
                  {Orders_text.orderdetail.track_order}
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShipmentDetails2;
