/* eslint-disable react/prop-types */
import React from 'react';

import { Orders_text } from '../../../constant/Translation/OrdersText';

import { changeDateFormat } from '../../Shared/Constant/Constant';
import {
  changeStatus,
  currentOrderStatus,
  orderStatus,
} from '../../Shared/Constant/Status';
import PricingItems from './PricingItems';

const OrderSummary = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  return (
    <div>
      <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-button  p-4   sm:p-7   border-opacity-40">
        <div className="flex justify-between items-center">
          <p className=" text-lg text-black font-semibold   ">
            {Orders_text.orderdetail.order_summary}
          </p>
          {(order_details?.legacy || order_details?.order_status == 6) && (
            <p className="     rounded-button text-primary font-semibold">
              {currentOrderStatus(order_details?.order_status)}
            </p>
          )}
        </div>
        <div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.order_created}
            </p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'DD/MM/YYYY')}
            </p>
          </div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.order_time}
            </p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'hh:mm A')}
            </p>
          </div>
        </div>
        <PricingItems order_details={order_details} />
      </div>
    </div>
  );
};

export default OrderSummary;
