export const HeaderDefaultColumnStyles = {
  flex_direction: 'row',
  column_gap: '8px',
  row_gap: '8px',
  align_items: 'center',
};

export const HeaderSearchBlockStyles = {
  max_width: '240px',
  width: '100%',
  border_radius: 'var(--button_styles_border_radius)',
  background: 'transparent',
  shadow: '0px 8px 28px rgba(0, 0, 0, 0.05)',
  border_color: 'var(--primary_color)',
  height: '40px',
  text_color: '#000000',
};
