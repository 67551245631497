import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';

import { changeDateFormat } from '../Shared/Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import { myStore, storeSelector } from '../../store/feature/storeSlice';

import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import { order_notification_text } from '../Shared/Constant/TextConstant/NotificationsText';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Notification_text } from '../../constant/Translation/Notification';
import {
  getMoreNotifications,
  notificationsSelector,
} from '../../store/feature/notificationSlice';

const Notifications4 = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { auth_key, login, user_details } = useSelector(authSelector);

  //
  const { notifications, page, total_records } = useSelector(
    notificationsSelector
  );

  const fetch_more = () => {
    dispatch(getMoreNotifications({ page: page + 1 }));
  };

  return (
    <>
      <div className=" text-right   ">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex rtl:flex-row-reverse justify-center items-center w-full text-sm font-medium  p-3 rounded-full  transition duration-400   bg-[#22222213]  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-[#6e686e]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <ChevronDownIcon
                className="w-5 h-5 text-[#6e686e]  -mr-1 "
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={[
                'absolute ltr:-right-28 rtl:-left-28 ltr:md:right-0 rtl:md:left-0  mt-2 origin-top-right bg-white    rounded-card   shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-3 z-50',
                notifications !== null && notifications?.length !== 0
                  ? 'h-96  overflow-y-auto    scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-300'
                  : 'h-48 ',
              ].join(' ')}
            >
              {/* <div className="w-[15px] h-[15px] bg-[#fff] absolute  right-28 md:right-0   transform rotate-45  -top-2   mr-7    border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" /> */}

              <div className="  ">
                {notifications !== null && notifications?.length !== 0 ? (
                  <div className="  w-60  xxs:w-72  h-[80vh]     ">
                    {notifications?.map((nt, index) => {
                      if (nt.type == 1) {
                        return (
                          <Menu.Item>
                            {({ active }) => (
                              <Link href={'a/my-account'}>
                                <a
                                  key={Math.random()}
                                  className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                                  // onClick={() => router.push('a/my-account?page=1')}
                                >
                                  <div className="     flex-none   w-[26px]   h-[26px] relative rounded-full overflow-hidden ">
                                    {nt?.account?.images.length > 0 && (
                                      <img
                                        src={nt.account.images[0]}
                                        className="h-full w-full object-cover object-left-top"
                                        alt="image"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <h2 className=" text-sm font-semibold text-[#050505]">
                                      {dynamic_translation_set(
                                        Notification_text.notification
                                          .account_message,
                                        nt?.user?.first_name
                                      )}
                                    </h2>
                                    <p className="text-sm font-normal text-primary mt-1">
                                      {changeDateFormat(
                                        nt.created_at,
                                        'DD-MM-YYYY, h:mm:ss a'
                                      )}
                                    </p>
                                  </div>
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      }
                      if (nt.type == 2) {
                        return (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                href={`/l/${
                                  nt?.listing?.id
                                }-${nt?.listing?.title.replace(
                                  /[ /?,#=]+/g,
                                  '+'
                                )}`}
                              >
                                <a
                                  key={Math.random()}
                                  className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                                >
                                  <div className="    flex-none    w-[26px]   h-[26px] relative rounded-full overflow-hidden ">
                                    {nt?.listing?.images.length > 0 && (
                                      <img
                                        src={nt.listing.images[0]}
                                        className="h-full w-full object-cover object-left-top"
                                        alt="image"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <h2 className=" text-sm font-semibold text-[#050505]">
                                      {dynamic_translation_set(
                                        Notification_text.notification
                                          .liked_your_listing,
                                        nt?.user?.first_name
                                      )}
                                    </h2>
                                    <p className="text-sm font-normal text-primary mt-1">
                                      {changeDateFormat(
                                        nt.created_at,
                                        'DD-MM-YYYY, h:mm:ss a'
                                      )}
                                    </p>
                                  </div>
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      }

                      if (nt.type === 3) {
                        if (
                          getCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) ==
                            true &&
                          getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) ==
                            nt?.metadata.account_id
                        ) {
                          return (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href={`/a/orders/${nt?.metadata.reference_number}?store_id=${nt?.metadata.account_id}`}
                                >
                                  <a
                                    key={Math.random()}
                                    className="px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="   flex-none    w-[26px]   h-[26px] relative  overflow-hidden    text-[#6e686e]  flex items-center justify-center "
                                      fill="none"
                                      viewBox=" 0 26 26 "
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                      />
                                    </svg>
                                    <div>
                                      <h2 className=" text-sm font-semibold text-[#050505]">
                                        {order_notification_text(
                                          nt?.metadata?.order_status
                                        )}
                                      </h2>
                                      <p className="text-sm font-normal text-primary mt-1">
                                        {changeDateFormat(
                                          nt.created_at,
                                          'DD-MM-YYYY, h:mm:ss a'
                                        )}
                                      </p>
                                    </div>
                                  </a>
                                </Link>
                              )}
                            </Menu.Item>
                          );
                        } else if (
                          getCookie(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) !=
                            true ||
                          getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) !=
                            nt?.metadata.account_id
                        ) {
                          return (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  href={`/orders/${nt?.metadata?.reference_number}`}
                                >
                                  <a
                                    key={Math.random()}
                                    className="  px-2 py-3 w-full min-h-[60px] my-2  flex gap-3  items-start cursor-pointer"
                                    // onClick={() => order_link(nt?.reference_id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="   flex-none     w-[26px]   h-[26px] relative  overflow-hidden    text-[#6e686e]  flex items-center justify-center "
                                      fill="none"
                                      viewBox=" 0 26 26 "
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                      />
                                    </svg>
                                    <div>
                                      <h2 className=" text-sm font-semibold text-[#050505]">
                                        {order_notification_text(
                                          nt?.metadata?.order_status
                                        )}
                                      </h2>
                                      <p className="text-sm font-normal text-primary mt-1">
                                        {changeDateFormat(
                                          nt.created_at,
                                          'DD-MM-YYYY, h:mm:ss a'
                                        )}
                                      </p>
                                    </div>
                                  </a>
                                </Link>
                              )}
                            </Menu.Item>
                          );
                        }
                      }
                    })}
                    {Number(total_records) != notifications?.length && (
                      <div className="py-3 flex justify-center">
                        <p
                          className=" font-semibold text-xs text-primary cursor-pointer"
                          onClick={() => fetch_more()}
                        >
                          {Notification_text.notification.load_morea}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className=" w-60 h-[100px] overflow-auto    scrollbar-thin flex justify-center items-center">
                    <h2 className="px-3 py-4 text-center text-base font-medium text-primary">
                      {
                        Notification_text.notification
                          .no_notification_at_the_moment
                      }
                    </h2>
                  </div>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default Notifications4;
