import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import Dropdown from '../Shared/utils/Dropdown';
import Transition from '../Shared/utils/Transition';
import HeaderProfile from '../HeaderProfileBox/HeaderProfile3';
import StoreButton from './StoreButton';
import SearchBox from '../SearchBox/SearchBox';
import CustomSearchBox from '../SearchBox/CustomSearchBox';
import axios from 'axios';
import { useRouter } from 'next/dist/client/router';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import { Menubar_text } from '../../constant/Translation/Heading';
import { getCookie } from 'cookies-next';
import {
  categories,
  categorySelector,
} from '../../store/feature/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { myStore } from '../../store/feature/storeSlice';
import { slug as SlugChecker } from 'github-slugger';

function Header8() {
  const dispatch = useDispatch();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);
  const router = useRouter();
  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  //
  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);

  // Store Fetch and categories fetch
  let userDetails = JSON.parse(localStorage.getItem('user_details'));
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        myStore({
          prams: {
            page: 1,
            type: 'accounts',
            user_id: userDetails.id,
          },
        })
      );
    }
    dispatch(
      categories({
        prams: { parent: 0, type: 'listings' },
      })
    );
  }, []);
  const { all_categories } = useSelector(categorySelector);

  return (
    <header
      className={`sticky top-0 w-full z-40 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && 'bg-white  blur  shadow-lg'
      }`}
    >
      <div className=" relative   mx-auto px-5 sm:px-6 md:px-16">
        <div
          className="flex items-center justify-between  "
          style={{
            height: `calc(${
              TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height || 50
            }px + 14px)`,
          }}
        >
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/">
              <div className="block">
                {logo && (
                  <Link href="/" passHref={true}>
                    <a className=" flex items-center   relative cursor-pointer ">
                      <img
                        src={logo}
                        style={{
                          height: `${
                            TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height || 50
                          }px`,
                          width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                          objectFit:
                            TYPE_CONSTANT.GENERAL_CONFIGS?.logo_object_fit ??
                            'contain',
                        }}
                        alt="logo"
                      />
                    </a>
                  </Link>
                )}
              </div>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="text-base font-semibold flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  href={{
                    pathname: '/l',
                  }}
                  passHref
                >
                  <a className="text-gray-800 hover:text-primary px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    {Menubar_text.more.all_listings}
                  </a>
                </Link>
              </li>

              <li>
                <Link
                  href={{
                    pathname: '/a',
                  }}
                  passHref
                >
                  <a className="text-gray-800 hover:text-primary px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    {Menubar_text.more.all_accounts}
                  </a>
                </Link>
              </li>

              {/* 1st level: hover */}
              <Dropdown title={Menubar_text.more.categories}>
                {/* 2nd level: hover */}
                {all_categories?.map((item, index, array) => {
                  return (
                    <li key={index}>
                      <Link
                        href={{
                          pathname: `/lc/[slug]`,
                          query: {
                            slug: item.slug
                              ? item.slug
                              : `${item?.id}-${SlugChecker(item.name)}`,
                          },
                        }}
                      >
                        <a className="font-medium text-sm text-gray-800 hover:text-primary flex py-2 px-5 leading-tight">
                          {item.name.length > 20
                            ? item.name.substring(0, 19) + '.'
                            : item.name}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </Dropdown>
            </ul>

            {/* Desktop sign in links */}
            {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) ? (
              <ul className="flex flex-grow justify-end flex-wrap items-center gap-3">
                <SearchBox />
                {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
                  <div className="  ">
                    <Add_listing_button
                      title={Menubar_text.more.header_sell_btn_title}
                    />
                  </div>
                )}
                {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && <StoreButton />}
                <HeaderProfile />
              </ul>
            ) : (
              <ul className="flex flex-grow justify-end flex-wrap items-center gap-3">
                {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 && (
                  <div className="  ">
                    <Add_listing_button
                      title={Menubar_text.more.header_sell_btn_title}
                    />
                  </div>
                )}
                <li>
                  <Link href={`/sign-in?to=${router.asPath}`}>
                    <a className="font-medium text-gray-800 hover:text-primary px-5 py-3 flex items-center transition duration-150 ease-in-out">
                      {Menubar_text.more.signin_signup}
                    </a>
                  </Link>
                </li>
              </ul>
            )}
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden gap-3">
            <ul>
              <CustomSearchBox />
            </ul>
            <ul>
              <HeaderProfile />
            </ul>
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-primary"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2">
                  <li>
                    <Link
                      href={{
                        pathname: '/l',
                      }}
                      passHref
                    >
                      <a className="flex text-gray-800 hover:text-primary py-2">
                        {Menubar_text.more.all_listings}
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link
                      href={{
                        pathname: '/a',
                      }}
                      passHref
                    >
                      <a className="flex text-gray-800 hover:text-primary py-2">
                        {Menubar_text.more.all_accounts}
                      </a>
                    </Link>
                  </li>

                  <li className="py-2 my-2 border-t border-b border-gray-200">
                    <span className="flex text-gray-800 hover:text-primary py-2">
                      {Menubar_text.more.categories}
                    </span>
                    <ul className="pl-4">
                      {all_categories?.map((item, index, array) => {
                        return (
                          <li key={index}>
                            <Link
                              href={{
                                pathname: `/lc/[slug]`,
                                query: {
                                  slug: item.slug
                                    ? item.slug
                                    : `${item?.id}-${SlugChecker(item.name)}`,
                                },
                              }}
                            >
                              <a className="text-sm flex font-medium text-gray-800 hover:text-primary py-2">
                                {item.name.length > 20
                                  ? item.name.substring(0, 19) + '.'
                                  : item.name}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  {!login && (
                    <>
                      <li>
                        <Link href={`/sign-in?to=${router.asPath}`}>
                          <a className="flex font-medium w-full text-gray-800 hover:text-primary py-2 justify-center">
                            {Menubar_text.more.signin_signup}
                          </a>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header8;
