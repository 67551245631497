import React, { useEffect, useState } from 'react';
import { authSelector } from '../../store/feature/authSlice';
import Link from 'next/link';

import { useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import axios from 'axios';
import { categorySelector } from '../../store/feature/categorySlice';
import { slug as SlugChecker } from 'github-slugger';

const Drawer2 = ({ closeDrawer }) => {
  const { all_categories } = useSelector(categorySelector);

  const router = useRouter();
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    setLogo(localStorage.getItem('logo'));
  }, [0]);

  return (
    <div>
      <div className=" sticky top-0  bg-white flex   justify-between  items-center   px-3 mb-2 shadow-md">
        {logo && (
          <Link href="/" passHref={true}>
            <a className=" w-auto block px-2      cursor-pointer py-4">
              <img
                src={logo}
                className="   h-[50px] object-contain"
                alt="logo"
              />
            </a>
          </Link>
        )}
        <button onClick={closeDrawer} className=" ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* <div className="pb-[10px] px-2">
          <SearchBox />
        </div> */}
      </div>
      <div>
        {all_categories?.map((item) => {
          const query =
            item.name !== 'More'
              ? {
                  slug: item.slug
                    ? item.slug
                    : `${item?.id}-${SlugChecker(item.name)}`,
                }
              : '';
          return (
            <div key={item.id}>
              <Link
                href={{
                  pathname: `${item.name !== 'More' ? '/lc/[slug]' : '/lc'}`,
                  query,
                }}
                passHref={true}
              >
                <div
                  className={[
                    'w-full h-12 flex   items-center     cursor-pointer  hover:bg-secondary mb-1',
                    router?.query?.slug ===
                    (item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`)
                      ? 'bg-secondary'
                      : '',
                  ].join(' ')}
                >
                  <p
                    className={[
                      ' text-sm font-semibold   ml-5 ',
                      router?.query?.slug ===
                      (item.slug
                        ? item.slug
                        : `${item?.id}-${SlugChecker(item.name)}`)
                        ? 'text-primary'
                        : ' text-default_gray',
                    ].join(' ')}
                  >
                    {item.name}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Drawer2;
