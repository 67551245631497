import Link from 'next/link';
import React from 'react';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const HeaderMenuLink = ({ data }) => {
  return (
    <Link href={data?.url ?? '#'}>
      <a
        className="px-2  h-full  header-menu-text"
        target={data?.target ?? '_parent'}
        rel="noopener noreferrer"
      >
        {get_data_by_current_language({
          data_list: data?.translated_data,
          key_name: 'button_title',
          default_data: data?.button_title,
        })}
      </a>
    </Link>
  );
};

export default HeaderMenuLink;
