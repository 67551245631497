import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { authSelector } from '../../store/feature/authSlice';
import Cookies from 'js-cookie';
import Add_listing_button from '../Shared/Buttons/Add_listing_button';
import Link from 'next/link';

const Sub_footer = ({ title }) => {
  const { login, user_details } = useSelector(authSelector);
  const router = useRouter();
  const is_draft = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  return (
    <div className="w-full bg-white my-20 ">
      <div className=" max-w-[95%] sm:max-w-[80%] md:max-w-[50%] mx-auto px-4 py-4 flex flex-col items-center gap-3">
        <h3 className="h3  text-center">
          {is_draft
            ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_title
              ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_title
              : 'Cant find what you need?'
            : TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_title
            ? TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_title
            : 'Cant find what you need?'}
        </h3>
        <p className="paragraph text-center">
          {is_draft
            ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_description
              ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_description
              : 'Build your own listings in a matter of hours - Whether just for your own user or others too.'
            : TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_description
            ? TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_description
            : 'Build your own listings in a matter of hours - Whether just for your own user or others too.'}
        </p>

        {TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_button_link ? (
          <Link
            href={TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_button_link ?? '#'}
          >
            <a
              className=" btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary "
              target={'_blank'}
            >
              {TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_button_title ?? title}
            </a>
          </Link>
        ) : (
          <Add_listing_button
            title={
              is_draft
                ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_button_title
                  ? TYPE_CONSTANT.DRAFT_STYLES?.general?.sub_footer_button_title
                  : title
                : TYPE_CONSTANT.GENERAL_CONFIGS.sub_footer_button_title
                ? TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer_button_title
                : title
            }
            btn_styles={
              'btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary '
            }
          />
        )}
      </div>
    </div>
  );
};

export default Sub_footer;
