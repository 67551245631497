/* eslint-disable react/prop-types */
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector, logout } from '../../store/feature/authSlice';
import { useRouter } from 'next/dist/client/router';

import { myStore, storeSelector } from '../../store/feature/storeSlice';
import { account_menus, customer_menus } from './desktopMenu';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menubar_text } from '../../constant/Translation/Heading';
import { sign_in_icon } from '../Shared/Constant/Icons/AllIcons';
import { getCookie } from 'cookies-next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useReducer } from 'react';
import { all_menus } from './menues';
import DefaultMenuComponent from './Menu/DefaultMenuComponent';
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/react/outline';
import DynamicProfileMenusByEditor from './Menu/DynamicProfileMenusByEditor';

const DynamicHeaderProfile = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  // console.log(my_stores);

  const [openMenu, setOpenMenu] = useState(false);
  const { profile_pic, login } = useSelector(authSelector);
  const open_menu_ref = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!open_menu_ref.current) return;
      if (
        !openMenu ||
        open_menu_ref.current.contains(target) ||
        open_menu_ref.current.contains(target)
      )
        return;
      setOpenMenu(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  return (
    <>
      <div className="bg-transparent flex rtl:flex-row-reverse items-center  cursor-pointer   group relative ">
        {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) ? (
          <div
            className="flex   items-center rtl:flex-row-reverse"
            id="profile_section"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {profile_pic !== undefined && profile_pic !== '' ? (
              <div className=" w-10 h-10 relative rounded-full overflow-hidden">
                <img
                  alt="profile picture"
                  src={profile_pic}
                  className="h-full w-full object-cover object-left-top"
                />
              </div>
            ) : (
              <UserCircleIcon className="h-10 w-10   text-[#6e686e]" />
            )}
            <ChevronDownIcon className=" w-4 ml-1  " />
          </div>
        ) : (
          <div>
            <button
              className="btn-primary-fill"
              onClick={() => router.push(`/sign-in?to=${router.asPath}`)}
            >
              <span className=" hidden  ms:block "> {sign_in_icon}</span>
              <span className=" ltr:ms:ml-[6px] rtl:ms:mr-1.5">
                {' '}
                {Menubar_text.more.signin_signup}
              </span>
            </button>
          </div>
        )}

        {getCookie(`${TYPE_CONSTANT.DOMAIN}_login`) && (
          <div
            id="profileMenus"
            ref={open_menu_ref}
            className={[
              `  md:group-hover:block  absolute top-0 ltr:right-0    rtl:left-0   z-[60]   ms:pt-6   transition duration-1000  `,
              openMenu ? 'block' : 'hidden',
            ].join(' ')}
            style={{
              marginTop: `${
                window?.innerWidth > 452
                  ? document.getElementById('profile_section')?.offsetHeight - 8
                  : document.getElementById('profile_section')?.offsetHeight - 0
              }px`,
            }}
          >
            {TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_dynamic_profile_menu ? (
              <DynamicProfileMenusByEditor />
            ) : (
              <DefaultMenuComponent />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicHeaderProfile;
