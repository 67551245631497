import { useRouter } from 'next/dist/client/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { check_login } from '../../constant/check_auth';
import { refreshPage } from '../../store/feature/authSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
// import { profile_order_details } from '../../tradly.config.js';
import ProductMainLayout from '../../themes/product/MainLayout';
import EventMainLayout from '../../themes/event/MainLayout';
import SimpleMainLayout from '../../themes/simple/MainLayout';
import EtsyMainLayout from '../../themes/Art & Craft/MainLayout';
import SaasMainLayout from '../../themes/saas/CustomLayout';
import CrowdfundingMainLayout from '../../themes/Crowdfunding/MainLayout';

import ProductOrderDetailsPageLayout from '../../themes/product/OrderDetailsPageLayout';
import EventOrderDetailsPageLayout from '../../themes/event/OrderDetailsPageLayout';
import SaasOrderDetailsPageLayout from '../../themes/saas/OrderDetailsPageLayout';
import SimpleOrderDetailsPageLayout from '../../themes/simple/OrderDetailsPageLayout';
import { seo_text } from '../../constant/static_text';
import SEO_Head from '../../constant/SEO_Head';
import {
  set_domain_name,
  encryptDataWithAES,
  decryptDataWithAES,
  set_host_name,
} from '../../constant/sdk_set';
import { Common_ssr_functions } from '../../constant/SSR_functions';
import {
  set_root_configs,
  ssr_configs_set,
} from '../../constant/Root_configs_set';
import { ssr_translation_functions } from '../../constant/Translation/SSR_translation';
import { Menubar_text } from '../../constant/Translation/Heading';
import { General_text } from '../../constant/Translation/General';
import { Notification_text } from '../../constant/Translation/Notification';
import { Orders_text } from '../../constant/Translation/OrdersText';
import {
  all_integrations_data,
  metadata,
  structuredData,
} from '../../constant/Metadata';
import { set_root_translation } from '../../constant/Translation/translation_set';
import { Address_text } from '../../constant/Translation/Address';
import OrderDetailsPageLayout from '../../themes/common_layouts/OrderDetailsPageLayout';
import ExpertMainLayout from '../../themes/expert_directory/MainLayout';
import IntegrationsMainLayout from '../../themes/Integration/MainLayout';
import B2CProductMainLayout from '../../themes/product(b2c)/MainLayout';
import B2CEventMainLayout from '../../themes/event(b2c)/MainLayout';
import B2CEtsyMainLayout from '../../themes/Art & Craft(b2c)/MainLayout';
import B2CCrowdfundingMainLayout from '../../themes/Crowdfunding(b2c)/MainLayout';
import SingleCartMainLayout from '../../themes/singlecart/MainLayout';
import SimpleGalleryMainLayout from '../../themes/simple_gallery/MainLayout';
import JobPortalMainLayout from '../../themes/job portal/MainLayout';
import RestaurantMainLayout from '../../themes/restaurant/MainLayout';
import CustomSaasMainLayout from '../../themes/custom saas/MainLayout';
import BlankMainLayout from '../../themes/blank/MainLayout';

const order_details = ({ configs_list_data, client_translations }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  // Configs decryption
  const [configs_list, setConfigsList] = useState(
    JSON.parse(decryptDataWithAES(configs_list_data))
  );

  useEffect(() => {
    // Decrypt the data when the prop value changes
    const decryptedValue = JSON.parse(decryptDataWithAES(configs_list_data));
    setConfigsList(decryptedValue);
  }, [configs_list_data]);

  //

  // SEO meat data
  metadata.title = seo_text.order_details_page_title;
  metadata.meta_description = seo_text.order_details_page_description;
  metadata.image = configs_list?.seo?.meta_image
    ? configs_list?.seo?.meta_image
    : configs_list?.general?.web_logo;
  all_integrations_data.google_tag_manager =
    configs_list?.integrations?.google_tag_manager;

  all_integrations_data.facebook_pixel =
    configs_list?.integrations?.facebook_pixel;
  structuredData.json_data = {};
  metadata.url = typeof window !== 'undefined' && window.location.href;
  //
  if (typeof window !== 'undefined') {
    set_root_configs({
      is_draft: router?.query?.draft,
      all_configs: configs_list,
    });

    // translation set
    set_root_translation({
      translation_values: client_translations,
      group_names: [
        'more',
        'general',
        'notification',
        'orderlist',
        'orderdetail',
        'address',
        'addresslist',
      ],
      translation_file: [
        Menubar_text,
        General_text,
        Notification_text,
        Orders_text,
        Address_text,
      ],
    });
  }

  // refresh action call
  useEffect(() => {
    window.addEventListener('focus', () => {
      dispatch(refreshPage({}));
    });
    dispatch(refreshPage({}));
  }, []);

  // This function not working from tradly configs file , will enhance letter
  const profile_order_details = () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <OrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<OrderDetailsPageLayout />);
    }
  };

  // Default layout
  // Default Layout function
  const default_layout = (page) => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return <BlankMainLayout>{page}</BlankMainLayout>;
        break;
      case 1:
        return <ProductMainLayout>{page}</ProductMainLayout>;
        break;
      case 2:
        return <EventMainLayout>{page}</EventMainLayout>;
        break;
      case 3:
      case 3.2:
        return <SaasMainLayout>{page}</SaasMainLayout>;
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {page}
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return <CrowdfundingMainLayout>{page}</CrowdfundingMainLayout>;
        break;

      case 6:
        return <SimpleMainLayout>{page}</SimpleMainLayout>;
        break;
      case 7:
        return (
          <ExpertMainLayout>
            <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
          </ExpertMainLayout>
        );
        break;
      case 8:
        return (
          <IntegrationsMainLayout>
            <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
          </IntegrationsMainLayout>
        );
        break;
      case 10:
        return <B2CProductMainLayout>{page}</B2CProductMainLayout>;
        break;

      case 11:
        return <B2CEventMainLayout>{page}</B2CEventMainLayout>;
        break;
      case 12:
        return (
          <B2CEtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {page}
            </div>
          </B2CEtsyMainLayout>
        );
        break;

      case 13:
        return <B2CCrowdfundingMainLayout>{page}</B2CCrowdfundingMainLayout>;
        break;

      case 14:
        return (
          <SingleCartMainLayout>
            <div className="max-w-7xl mx-auto px-3  md:px-9 mt-3">{page}</div>
          </SingleCartMainLayout>
        );
        break;
      case 15:
        return <SimpleGalleryMainLayout>{page}</SimpleGalleryMainLayout>;
        break;
      case 17:
        return <JobPortalMainLayout>{page}</JobPortalMainLayout>;
        break;

      case 18:
        return (
          <RestaurantMainLayout>
            <div className=" max-w-[1140px] mx-auto px-4  mt-8">{page}</div>
          </RestaurantMainLayout>
        );
        break;
      case 20:
        return <CustomSaasMainLayout>{page}</CustomSaasMainLayout>;
        break;
      default:
        return <ProductMainLayout>{page}</ProductMainLayout>;
    }
  };

  return (
    check_login(router) && (
      <>
        <SEO_Head />
        {profile_order_details()}
      </>
    )
  );
};

export default order_details;

// Server Side Function
export async function getServerSideProps({ params, req, res, query }) {
  var host = set_domain_name(req);

  const auth_key = req.cookies[`${set_host_name(req)}_auth_key`];
  let default_language =
    req.cookies[`${set_host_name(req)}_default_language`] ?? undefined;
  const currency = req.cookies[`${set_host_name(req)}_default_currency`];

  // SSR FUnctions
  const { configs_list } = await Common_ssr_functions({
    host,
    auth_key,
    currency,
    req,
    res,
    query,
  }).then(async (res) => {
    return res;
  });

  // configs set
  ssr_configs_set({ is_draft: query.draft, all_configs: configs_list });

  // client_translations
  const { client_translations } = await ssr_translation_functions({
    host,
    auth_key,
    currency,
    req,
    res,
    query,
    translation_group: [
      'more',
      'general',
      'notification',
      'orderlist',
      'orderdetail',
      'address',
      'addresslist',
    ],
    translation_file: [
      Menubar_text,
      General_text,
      Notification_text,
      Orders_text,
      Address_text,
    ],
  }).then(async (res) => {
    return res;
  });

  // SEO meat data
  metadata.title = seo_text.order_details_page_title;
  metadata.meta_description = seo_text.order_details_page_description;
  metadata.url = req?.headers?.host + req?.url;
  metadata.image = configs_list?.seo?.meta_image
    ? configs_list?.seo?.meta_image
    : configs_list?.general?.web_logo;
  all_integrations_data.google_tag_manager =
    configs_list?.integrations?.google_tag_manager;

  all_integrations_data.facebook_pixel =
    configs_list?.integrations?.facebook_pixel;
  structuredData.json_data = {};

  return {
    props: {
      configs_list_data: encryptDataWithAES(JSON.stringify(configs_list)),

      client_translations,
    },
  };
}
