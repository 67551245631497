import React, { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { getCookie, setCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import AllIcons from '../Shared/Constant/Icons/AllIcons';
import ModalBasic from '../Shared/Modal/ModalBesic';
import { General_text } from '../../constant/Translation/General';
import PopupUI from '../Shared/PopUp/UI/PopupUI';
import { Menubar_text } from '../../constant/Translation/Heading';
import SelectFromList from '../../UI/FormFields/SelectFromList';
import { TSICONS } from '../../constant/Icons/TSICONS';

const GlobalSwitch = () => {
  const router = useRouter();
  const { login } = useSelector(authSelector);

  // Language
  const [languages, setLanguages] = useState(TYPE_CONSTANT.AVAILABLE_LANGUAGES);
  const [selected_languagesCode, setSelectedLanguageCode] = useState(
    getCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`)
  );
  const [selected_language_details, setSelectedLanguageDetails] = useState([]);
  TYPE_CONSTANT.DIR = getCookie(`${TYPE_CONSTANT.DOMAIN}_direction`);
  TYPE_CONSTANT.SELECTED_LANGUAGE = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_language`
  );

  // Currency
  const [currencies, setCurrencies] = useState(TYPE_CONSTANT.CURRENCIES);
  const [selected_currencyCode, setSelectedCCurrencyCode] = useState(
    getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`)
  );
  const [selected_currency_details, setSelectedCurrencyDetails] = useState([]);
  TYPE_CONSTANT.CURRENCY = getCookie(
    `${TYPE_CONSTANT.DOMAIN}_default_currency`
  );

  // Effects
  useEffect(() => {
    setSelectedLanguageCode(
      getCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`)
    );
    setSelectedCCurrencyCode(
      getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`)
    );
  }, [0]);
  useEffect(() => {
    //
    const local_storage_languages =
      localStorage.getItem('available_languages') != undefined &&
      JSON.parse(localStorage.getItem('available_languages'));

    if (local_storage_languages?.length > 0) {
      setLanguages(local_storage_languages);
      setSelectedLanguageDetails(
        local_storage_languages?.filter(
          (language) => language.code == selected_languagesCode
        )?.[0]
      );
    }

    //
    const local_storage_currencies =
      localStorage.getItem('available_currencies') != undefined &&
      JSON.parse(localStorage.getItem('available_currencies'));
    if (local_storage_currencies) {
      setCurrencies(local_storage_currencies);
      setSelectedCurrencyDetails(
        local_storage_currencies?.filter(
          (currency) => currency.code == selected_currencyCode
        )?.[0]
      );
    }
  }, [localStorage.getItem('available_languages')]);

  // language_change
  const language_change = (selected_language) => {
    setSelectedLanguageCode(selected_language.code);
    setSelectedLanguageDetails(selected_language);
  };
  // language_change
  const currency_change = (selected_currency) => {
    setSelectedCCurrencyCode(selected_currency.code);
    setSelectedCurrencyDetails(selected_currency);
  };

  //
  const [language_currency_section_open, setLanguageAndCurrencyStateOpen] =
    useState(false);

  // Save data
  const savHandler = (e) => {
    e.stopPropagation();

    setCookie(
      `${TYPE_CONSTANT.DOMAIN}_default_language`,
      selected_language_details?.code
    );
    setCookie(
      `${TYPE_CONSTANT.DOMAIN}_direction`,
      selected_language_details?.rtl ? 'rtl' : 'ltr'
    );
    setCookie(
      `${TYPE_CONSTANT.DOMAIN}_default_currency`,
      selected_currency_details?.code
    );
    setLanguageAndCurrencyStateOpen(false);
    window.location.reload();
  };

  return (
    <div className=" text-right  inline-block  ">
      <button
        className="inline-flex rtl:flex-row-reverse justify-center items-center w-full text-sm font-medium  p-2 rounded-full   transition duration-400  text-[#6e686e]  hover:bg-[#22222213]   "
        onClick={(e) => {
          e.stopPropagation();
          setLanguageAndCurrencyStateOpen(true);
        }}
      >
        {/* className="h-6 w-6 */}
        {AllIcons.global_icon}
      </button>

      <ModalBasic
        title="global-section"
        id="global-section-form"
        modalOpen={language_currency_section_open}
        setModalOpen={setLanguageAndCurrencyStateOpen}
        closeByOutsideClick={true}
        main_div_width={450}
      >
        <section className="  w-full  bg-white   min-h-[200px]   ">
          <PopupUI
            title={Menubar_text.more.regional_setting}
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setLanguageAndCurrencyStateOpen(false);
              },
            }}
            right_button={{
              title: General_text.general.save,
              click: (e) => savHandler(e),

              // icon: isLoading ? AllIcons.loading_icon : '',
            }}
          >
            <div className="px-10 py-6 pb-10 min-h-[290px] space-y-6">
              {/*  */}
              <SelectFromList
                title="Language"
                title_icon={TSICONS.language}
                default_option_text={General_text.general.select}
                set_new_value={(selected_language) => {
                  const find_selected_language_details = languages.filter(
                    (item) => item.code === selected_language
                  )[0];

                  language_change(find_selected_language_details);
                }}
                current_value={selected_languagesCode}
                drop_down_items={languages?.map((language) => {
                  let language_image = language?.image_path
                    ? language?.image_path
                    : language?.flag
                    ? language?.flag
                    : undefined;
                  return {
                    label: language.name,
                    value: language.code,
                    image: language_image,
                  };
                })}
                styles={{
                  popover_style: ' max-h-[200px]',
                  options_style:
                    ' hover:bg-gray-100 hover:text-primary  hover:shadow duration-200 p-2',
                }}
              />

              {/*  */}
              <SelectFromList
                title="Currency"
                title_icon={TSICONS.stack}
                default_option_text={General_text.general.select}
                current_value={selected_currencyCode}
                drop_down_items={currencies?.map((currency) => ({
                  label: currency.name,
                  value: currency.code,
                  image: currency?.image_path
                    ? currency?.image_path
                    : undefined,
                }))}
                set_new_value={(selected_currency) => {
                  const find_selected_currency_details = currencies.filter(
                    (item) => item.code === selected_currency
                  )[0];

                  currency_change(find_selected_currency_details);
                }}
                styles={{
                  popover_style: ' max-h-[200px]',
                  options_style:
                    ' hover:bg-gray-100 hover:text-primary  hover:shadow duration-200 p-2',
                }}
              />
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </div>
  );
};

export default GlobalSwitch;
