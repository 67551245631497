import React from 'react';
import { changeDateFormat } from '../../Shared/Constant/Constant';
import { changeStatus } from '../../Shared/Constant/Status';
import { Orders_text } from '../../../constant/Translation/OrdersText';

const OrderTimeline = ({ shipment = {}, order_details = {} }) => {
  const PickupTimelineStatus = [
    {
      status: 1,
      text: Orders_text.orderdetail.shipment_created,
    },
    {
      status: 2,
      text: Orders_text.orderdetail.shipment_in_progress,
    },
    {
      status: 4,
      text: Orders_text.orderdetail.ready_for_pickup,
    },
    {
      status: 7,
      text: Orders_text.orderdetail.shipment_delivered,
    },
  ];

  const DeliveryTimelineStatus = [
    {
      status: 1,
      text: Orders_text.orderdetail.shipment_created,
    },
    {
      status: 2,
      text: Orders_text.orderdetail.shipment_in_progress,
    },
    {
      status: 3,
      text: Orders_text.orderdetail.shipped,
    },
    {
      status: 7,
      text: Orders_text.orderdetail.shipment_delivered,
    },
  ];

  const checkStatusInHistory = (status) => {
    let available_status = shipment?.shipment_status_history?.filter(
      (history) => history.status == status
    );

    return available_status?.length > 0 ? available_status[0] : undefined;
  };

  return (
    <div className={'w-full h-min-[50px]  rounded-card      '}>
      <div className={['   '].join(' ')}>
        {[
          order_details?.shipping_method?.type === 'delivery'
            ? DeliveryTimelineStatus
            : order_details?.shipping_method?.type === 'pickup'
            ? PickupTimelineStatus
            : DeliveryTimelineStatus, //default
        ][0].map((history, index, array) => {
          const status_from_history = checkStatusInHistory(history.status);
          const next__status_from_history =
            array?.length - 1 !== index
              ? checkStatusInHistory(array[index + 1]?.status)
              : undefined;
          return (
            <div
              className={[
                ' relative flex items-start justify-between  pl-8   pb-6  ',
                'before:absolute before:top-1 ltr:before:left-0 rtl:before:right-0  before:h-4 before:w-4   before:rounded-full ',
                status_from_history
                  ? 'before:bg-primary'
                  : 'before:bg-gray-400',
                !next__status_from_history &&
                  status_from_history &&
                  'before:ring-4  before:ring-secondary',
                array?.length - 1 !== index &&
                  'after:w-1 after:h-full after:absolute after:top-2 ltr:after:left-1.5   rtl:after:right-1.5 after:rounded-md',
                status_from_history ? 'after:bg-primary' : 'after:bg-gray-400',
              ].join(' ')}
            >
              <p className=" flex-grow  w-full  text-sm font-semibold  text-gray-900 ltr:pr-3 rtl:pl-3">
                {history.text}
              </p>
              {status_from_history && (
                <div className=" flex-none flex flex-col items-end gap-2">
                  <p className="  text-[10px] font-normal text-gray-600  ">
                    {changeDateFormat(
                      status_from_history?.created_at,
                      'DD/MM/YYYY'
                    )}
                  </p>
                  <p className="  text-[10px] font-normal text-gray-600 ">
                    {changeDateFormat(
                      status_from_history?.created_at,
                      'hh:mm a'
                    )}
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderTimeline;
