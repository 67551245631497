/* eslint-disable react/prop-types */
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import ChildLevelCategories from './ChildLevelCategories';
import { slug as SlugChecker } from 'github-slugger';

const BuilderCategoryDropdown = ({ categories, current_category }) => {
  const router = useRouter();

  const [header_sections_height, setHeader_section_height] = useState(0);

  //
  useEffect(() => {
    const header_section =
      document.getElementById('header_section')?.offsetHeight ?? 0;
    setHeader_section_height(Number(header_section));
  }, [router]);
  //

  return (
    <div
      className="bg-transparent flex rtl:flex-row-reverse items-center  cursor-pointer   group relative "
      // onClick={openUserMenu}
    >
      <button
        className={[
          ' min-h-[44px]  px-1 header-menu-text gap-2 border-none  outline-none ',
          router?.query?.slug ==
            (current_category.slug
              ? current_category.slug
              : `${current_category?.id}-${SlugChecker(
                  current_category.name
                )}`) && '!text-[color:var(--headerMenuHoverTextColor)]',
        ].join(' ')}
        id={`${current_category.id}-ct-btn`}
        onClick={() => {
          router.push({
            pathname: `${
              current_category.name !== 'More' ? '/lc/[slug]' : '/lc'
            }`,
            query: {
              slug: current_category.slug
                ? current_category.slug
                : `${current_category?.id}-${SlugChecker(
                    current_category.name
                  )}`,
              page: 1,
            },
          });
        }}
      >
        <p>{current_category.name}</p>
        <svg
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=" w-3 h-3   "
        >
          <path
            d="M11.0002 1.17019C10.8128 0.983936 10.5594 0.879395 10.2952 0.879395C10.031 0.879395 9.77756 0.983936 9.59019 1.17019L6.00019 4.71019L2.46019 1.17019C2.27283 0.983936 2.01938 0.879395 1.75519 0.879395C1.49101 0.879395 1.23756 0.983936 1.05019 1.17019C0.956464 1.26315 0.88207 1.37375 0.831301 1.49561C0.780533 1.61747 0.754395 1.74818 0.754395 1.88019C0.754395 2.0122 0.780533 2.1429 0.831301 2.26476C0.88207 2.38662 0.956464 2.49722 1.05019 2.59019L5.29019 6.83019C5.38316 6.92392 5.49376 6.99831 5.61562 7.04908C5.73747 7.09985 5.86818 7.12599 6.00019 7.12599C6.1322 7.12599 6.26291 7.09985 6.38477 7.04908C6.50663 6.99831 6.61723 6.92392 6.71019 6.83019L11.0002 2.59019C11.0939 2.49722 11.1683 2.38662 11.2191 2.26476C11.2699 2.1429 11.296 2.0122 11.296 1.88019C11.296 1.74818 11.2699 1.61747 11.2191 1.49561C11.1683 1.37375 11.0939 1.26315 11.0002 1.17019Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <ul
        id={`${current_category.id}-ct-dropdown`}
        className={
          ' hidden  group-hover:block  fixed  pt-[26px]  z-[60]     transition duration-1000 w-auto h-auto   '
        }
        style={{ top: header_sections_height - 8 }}
      >
        <div className="   bg-[#fff] rounded-card   py-2  min-h-[150px]  border border-[rgba(250, 250, 250, 0.93)]  shadow-sm    max-h-[70VH] overflow-y-auto  scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-200 ">
          {categories?.map((category) => {
            const query =
              category.id !== 'more'
                ? {
                    slug: category.slug
                      ? category.slug
                      : `${category?.id}-${SlugChecker(category.name)}`,
                  }
                : '';

            return (
              <li>
                <Link
                  href={{
                    pathname: `${
                      category.name !== 'More' ? '/lc/[slug]' : '/lc'
                    }`,
                    query,
                  }}
                  passHref={true}
                >
                  <a
                    className={[
                      ' min-w-[150px]   px-4 py-1  justify-start  header-menu-text hover:bg-[var(--headerMenuHoverBackgroundColor)] ',
                      router?.query?.slug ==
                        (category.slug
                          ? category.slug
                          : `${category?.id}-${SlugChecker(category.name)}`) &&
                        '!text-[color:var(--headerMenuHoverTextColor)]',
                    ].join(' ')}
                  >
                    {category.name}
                  </a>
                </Link>
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
};

export default BuilderCategoryDropdown;
