import Link from 'next/link';
import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const SingleLink = ({ data, styles }) => {
  return (
    <Link href={data.url}>
      <a
        className=" text-xs text-[#4F4F4F] font-medium"
        style={get_dynamic_styles(styles?.link)}
        target={data?.target ?? '_blank'}
      >
        {get_data_by_current_language({
          data_list: data?.translated_data,
          key_name: 'title',
          default_data: data?.title,
        })}
      </a>
    </Link>
  );
};

export default SingleLink;
