import React from 'react';
import Header6 from '../../components/Header/Header6';
import SimpleFooter from '../../components/Footer/SimpleFooter';
import Header11 from '../../components/Header/Header11';
import SimpleFooter2 from '../../components/Footer/SimpleFooter2';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <div>
      {/* <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={`${pageDescription}`} />
        <meta property="og:title" content={`${pageTitle}`} key="title" />
      </Head> */}
      <div>
        <div>
          <Header11 />
        </div>
        <div className="  min-h-screen  w-full " id="template-components-body">
          <div>{children}</div>
        </div>
        <div className="  bg-footer mt-16 md:mt-20   min-h-[200px]  left-0 bottom-0  ">
          <SimpleFooter2 />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
