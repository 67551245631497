import React, { useEffect, useState } from 'react';
import AddressBox from '../../components/OrderDetails/AddressBox/AddressBox';
import CustomerDetails from '../../components/OrderDetails/CustomerDetails/CustomerDetails';
import ItemsSummary from '../../components/OrderDetails/ItemsSummary/ItemsSummary';
import OrderSummary from '../../components/OrderDetails/OrderSummary/OrderSummary';
import TotalAmountBox from '../../components/OrderDetails/TotalBox/TotalAmountBox';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  get_order_details,
  orderSelector,
} from '../../store/feature/orderSlice';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import PopUp from '../../components/Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import SuccessPopUp from '../../components/Shared/PopUp/Success';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import { Orders_text } from '../../constant/Translation/OrdersText';
import SellerDetails from '../../components/OrderDetails/CustomerDetails/SellerDetails';
import OrderDetailsHeader from '../../components/OrderDetails/OrderDetailsHeader';
import { General_text } from '../../constant/Translation/General';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Link from 'next/link';
import OrderTracking from '../../components/OrderDetails/OrderSummary/OrderTracking';
import DigitalProductDownload from '../../components/OrderDetails/DigitalProduct/DigitalProductDownload';
import OrderTimeline from '../../components/OrderDetails/OrderTimeline/OrderTimeline';
import AddressBox2 from '../../components/OrderDetails/AddressBox/AddressBox2';

const OrderDetailsPageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');

  const { auth_key } = useSelector(authSelector);

  useEffect(() => {
    if (auth_key) {
      dispatch(get_order_details({ authKey: auth_key, id: router.query.id }));
    }
  }, [auth_key, router.query.id]);
  const { order_details } = useSelector(orderSelector);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    setShowSuccess(false);
    setSuccess_message('');
  };

  return (
    order_details && (
      <div>
        {showError && (
          <OutsideClickHandler
            onOutsideClick={() => {
              showError && (setShowError(false), setError_message(''));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <PopUp
                  message={error_message || errorMessage}
                  closePopUP={closePopUP}
                />
              </div>
            </div>
          </OutsideClickHandler>
        )}
        {showSuccess && (
          <OutsideClickHandler
            onOutsideClick={() => {
              showSuccess && (setShowSuccess(false), setSuccess_message(''));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <SuccessPopUp
                  message={success_message}
                  closePopUP={closePopUP}
                />
              </div>
            </div>
          </OutsideClickHandler>
        )}

        {/* Breadcrumb  */}
        {order_details && (
          <div className="mb-2">
            <Breadcrumb
              lists={[
                { name: 'Orders', link: '/orders' },
                { name: `# ${order_details?.reference_number}`, link: '' },
              ]}
            />
          </div>
        )}
        {/* <CustomLoading/> */}
        <OrderDetailsHeader
          order_details={order_details}
          setShowError={setShowError}
          setError_message={setError_message}
          setShowSuccess={setShowSuccess}
          setSuccess_message={setSuccess_message}
        />
        <div className=" grid   grid-cols-[100%]  xl:grid-cols-[60%,40%]  2xl:grid-cols-[60%,35%]   mt-6">
          <div>
            <div>
              <ItemsSummary order_details={order_details} />
            </div>
            <div className=" mt-5">
              <CustomerDetails order_details={order_details} />
            </div>
            {/* {order_details?.account?.user && (
              <div className=" mt-5">
                <SellerDetails order_details={order_details} />
              </div>
            )} */}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.enable_feedback && (
              <p className=" px-3 mt-5 text-lg text-primary  flex items-start gap-2 flex-wrap justify-center md:justify-start">
                <span>{Orders_text?.orderdetail?.need_help}</span>
                <Link
                  href={`/feedback?type=orders&reference_number=${order_details?.reference_number}`}
                >
                  <a>
                    <span className="underline">
                      {General_text?.general.contact_us}
                    </span>
                  </a>
                </Link>
              </p>
            )}
          </div>
          <div className="  mt-5 xl:mt-0 ltr:xl:ml-10 rtl:xl:mr-10">
            <div>
              <OrderSummary
                order_details={order_details}
                setShowError={setShowError}
                setError_message={setError_message}
                setShowSuccess={setShowSuccess}
                setSuccess_message={setSuccess_message}
              />
            </div>
            {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
              true && (
              <div className="mt-5">
                <OrderTracking order_details={order_details} />
              </div>
            )}

            {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables ==
              true && (
              <div className="mt-5">
                <DigitalProductDownload order_details={order_details} />
              </div>
            )}

            <div className=" mt-5">
              {order_details?.legacy ? (
                <AddressBox order_details={order_details} />
              ) : (
                <AddressBox2 order_details={order_details} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default OrderDetailsPageLayout;
