import { useRouter } from 'next/router';
import React from 'react';
import { Menubar_text } from '../../constant/Translation/Heading';
import { sign_in_icon } from '../Shared/Constant/Icons/AllIcons';

const SignInButton = () => {
  const router = useRouter();
  return (
    <button
      className="flex-none min-w-[90px]  bg-primary text-sm text-white  px-2  py-[6px] flex items-center justify-center rounded-button "
      onClick={() => router.push(`/sign-in?to=${router.asPath}`)}
    >
      <span className=" hidden  ms:block "> {sign_in_icon}</span>
      <span className=" ltr:ms:ml-[6px] rtl:ms:mr-1.5">
        {Menubar_text.more.signin_signup}
      </span>
    </button>
  );
};

export default SignInButton;
