import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);

// Import Swiper styles
import 'swiper/css/bundle';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const CarouselCardBlock = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <Swiper
        slidesPerView={styles?.carousel?.slides_per_view ?? 'auto'}
        spaceBetween={styles?.carousel?.space_between ?? 30}
        pagination={{
          dynamicBullets: styles?.carousel?.dynamic_bullets ?? false,
          clickable: styles?.carousel?.clickable ?? false,
        }}
        autoplay={{
          delay: styles?.carousel?.delay ?? 2500,
          disableOnInteraction: false,
        }}
        className="  w-full h-full  "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {data?.cards?.map((item) => {
          return (
            <SwiperSlide
              className=" w-full   "
              style={get_dynamic_styles(styles?.card_styles)}
            >
              {/* Title */}
              {item?.title && (
                <p style={get_dynamic_styles(styles?.title)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'title',
                    default_data: item?.title,
                  })}
                </p>
              )}
              {/* Desc */}
              {item?.desc && (
                <p style={get_dynamic_styles(styles?.desc)}>
                  {get_data_by_current_language({
                    data_list: item?.translated_data,
                    key_name: 'desc',
                    default_data: item?.desc,
                  })}
                </p>
              )}
              {/* image */}
              {item?.image && (
                <img
                  style={get_dynamic_styles(styles?.image)}
                  src={item?.image}
                  alt="image"
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default CarouselCardBlock;
