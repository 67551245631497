/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Head from 'next/head';
import Footer2 from '../../components/Footer/Footer2';
import Header10 from '../../components/Header/Header10';
import Sub_footer from '../../components/Footer/Sub_footer';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <>
      <div>
        <Header10 />
        <div
          className=" mt-5 min-h-screen  overflow-x-hidden max-w-6xl mx-auto px-4 sm:px-6 "
          id="template-components-body"
        >
          <div>{children}</div>
        </div>
        <div className="   min-h-[200px]  left-0 bottom-0  ">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.sub_footer !== false && (
            <div>
              <Sub_footer title={'Add Integration'} />
            </div>
          )}
          <div className="  bg-footer mt-16 md:mt-20   min-h-[200px]  left-0 bottom-0  ">
            <Footer2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
