import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { angle_down } from '../Shared/Constant/Icons/AllIcons';
import {
  facebook_icon,
  instagram_icon,
  snapchat_icon,
  telegram_icon,
  tiktok_icon,
  twitter_icon,
  whatsapp_icon,
  youtube_icon,
} from '../Shared/Constant/Icons/socialIcons';
import axios from 'axios';
import tradly_icon from '../../public/tradly-Icon-192x192.png';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Menubar_text } from '../../constant/Translation/Heading';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../store/feature/categorySlice';

const Footer3 = () => {
  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [isSeeAllCategories, setIsSeeAllCategories] = useState(false);
  const router = useRouter();
  const is_draft = router?.query?.draft ? true : false;

  const { all_categories } = useSelector(categorySelector);

  return (
    <>
      <div className="w-full h-full   ">
        <div className="flex flex-col md:flex-row  items-center md:justify-between gap-4">
          {logo && (
            <Link href="/" passHref={true}>
              <a className=" block   relative">
                <img src={logo} className=" block h-[50px]    " alt="logo" />
              </a>
            </Link>
          )}
          <div>
            {TYPE_CONSTANT.SOCIAL_CONFIGS &&
              Object?.keys(TYPE_CONSTANT.SOCIAL_CONFIGS)?.length > 0 && (
                <div className="mt-4 flex items-center justify-center md:justify-start gap-3 flex-wrap ">
                  <h2 className="text-sm italic font-normal  ">
                    {Menubar_text.more.follow_us_title}
                  </h2>
                  <div className="flex items-center gap-3 flex-wrap">
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.facebook_pageurl && (
                      <Link
                        href={TYPE_CONSTANT.SOCIAL_CONFIGS?.facebook_pageurl}
                      >
                        <a target="_blank">{facebook_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.youtube_channelurl && (
                      <Link
                        href={TYPE_CONSTANT.SOCIAL_CONFIGS?.youtube_channelurl}
                      >
                        <a target="_blank">{youtube_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.twitter_handleurl && (
                      <Link
                        href={TYPE_CONSTANT.SOCIAL_CONFIGS?.twitter_handleurl}
                      >
                        <a target="_blank">{twitter_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.snapchat && (
                      <Link href={TYPE_CONSTANT.SOCIAL_CONFIGS?.snapchat}>
                        <a target="_blank">{snapchat_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.instagram_account && (
                      <Link
                        href={TYPE_CONSTANT.SOCIAL_CONFIGS?.instagram_account}
                      >
                        <a target="_blank">{instagram_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.telegram_url && (
                      <Link href={TYPE_CONSTANT.SOCIAL_CONFIGS?.telegram_url}>
                        <a target="_blank">{telegram_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.tiktok_url && (
                      <Link href={TYPE_CONSTANT.SOCIAL_CONFIGS?.tiktok_url}>
                        <a target="_blank">{tiktok_icon}</a>
                      </Link>
                    )}
                    {TYPE_CONSTANT.SOCIAL_CONFIGS?.whatsapp_number && (
                      <a
                        className=" cursor-pointer"
                        onClick={() =>
                          window.open(
                            `https://api.whatsapp.com/send/?phone=${TYPE_CONSTANT.SOCIAL_CONFIGS?.whatsapp_number}`
                          )
                        }
                        target="_blank"
                      >
                        {whatsapp_icon}
                      </a>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className=" mt-5    flex items-center justify-center md:justify-between flex-wrap gap-3">
          <div className="  md:order-1 flex items-center gap-4 flex-wrap">
            <p className=" text-sm  text-[#4F4F4F]">
              {`© ${new Date().getFullYear()} ${
                TYPE_CONSTANT.GENERAL_CONFIGS?.website_name
                  ? TYPE_CONSTANT.GENERAL_CONFIGS?.website_name
                  : ''
              }. ${Menubar_text.more.all_rights_reserve}`}
            </p>
            <Link href={'/sitemap-index.xml'}>
              <a
                className=" text-xs text-[#4F4F4F] font-medium"
                target="_blank"
              >
                {Menubar_text.more.sitemap}
              </a>
            </Link>

            {!TYPE_CONSTANT.GENERAL_CONFIGS?.hide_tradly_footer_note && (
              <Link
                href={`https://tradly.app?utm_source=plg&utm_medium=built_button&utm_campaign=${TYPE_CONSTANT.DOMAIN}`}
              >
                <a
                  className=" text-xs text-[#4F4F4F] font-medium"
                  target="_blank"
                >
                  Built with Tradly API
                </a>
              </Link>
            )}
          </div>{' '}
          <div className="  flex md:items-center justify-center md:justify-start gap-2 ">
            {TYPE_CONSTANT.GENERAL_CONFIGS?.how_it_works_url && (
              <Link href={TYPE_CONSTANT.GENERAL_CONFIGS?.how_it_works_url}>
                <a
                  className=" block    text-sm text-[#4F4F4F] font-normal  "
                  target="_blank"
                >
                  {Menubar_text.more.how_it_work}
                </a>
              </Link>
            )}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url && (
              <Link href={TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url}>
                <a
                  className=" block    text-sm text-[#4F4F4F] font-normal  "
                  target="_blank"
                >
                  {Menubar_text.more.terms_condition}
                </a>
              </Link>
            )}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy_url && (
              <Link href={TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy_url}>
                <a
                  className=" block    text-sm text-[#4F4F4F] font-normal  "
                  target="_blank"
                >
                  {Menubar_text.more.privacy_policy}
                </a>
              </Link>
            )}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.faq_url && (
              <Link href={TYPE_CONSTANT.GENERAL_CONFIGS?.faq_url}>
                <a
                  className=" block    text-sm text-[#4F4F4F] font-normal  "
                  target="_blank"
                >
                  {Menubar_text.more.faq}
                </a>
              </Link>
            )}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.support_url && (
              <Link href={TYPE_CONSTANT.GENERAL_CONFIGS?.support_url}>
                <a
                  className="block    text-sm text-[#4F4F4F] font-normal  "
                  target="_blank"
                >
                  {Menubar_text.more.support}
                </a>
              </Link>
            )}
            {TYPE_CONSTANT.GENERAL_CONFIGS?.custom_footer_links &&
              TYPE_CONSTANT.GENERAL_CONFIGS?.custom_footer_links?.length > 0 &&
              TYPE_CONSTANT.GENERAL_CONFIGS?.custom_footer_links.map((link) => {
                return (
                  <Link href={link.link}>
                    <a className=" block    pb-4" target="_blank">
                      <button className=" text-base text-[#4F4F4F] font-medium">
                        {link.title}
                      </button>
                    </a>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer3;
