import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { privacy_icon } from '../Shared/Constant/Icons/AllIcons';
import { logout } from '../../store/feature/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { myStore, storeSelector } from '../../store/feature/storeSlice';
import { Menubar_text } from '../../constant/Translation/Heading';
import { all_menus } from './menues';

export default function HeaderProfile4() {
  const router = useRouter();
  const dispatch = useDispatch();
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  //
  useEffect(() => {
    if (localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`)) {
      dispatch(
        myStore({
          prams: {
            page: 1,
            type: 'accounts',
            user_id: userDetails.id,
          },
        })
      );
    }
  }, []);

  // my_stores
  const { my_stores } = useSelector(storeSelector);

  return (
    <div className=" text-right   ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex rtl:flex-row-reverse justify-center items-center w-full text-sm font-medium  p-3 rounded-full  transition duration-400  hover:bg-[#22222213]   ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#6e686e]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
              />
            </svg>
            <ChevronDownIcon
              className="w-5 h-5 text-[#6e686e]   -mr-1  "
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute  ltr:right-0 rtl:left-0  min-w-[224px]  max-h-[80vh]  overflow-y-auto  mt-2 origin-top-right bg-white    rounded-card   shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-3 z-50">
            <div className="w-[15px] h-[15px] bg-[#fff] absolute  ltr:right-0 rtl:left-0   transform rotate-45  -top-2   ltr:mr-5   rtl:ml-5  border-l border-t border-[rgba(250, 250, 250, 0.93)]  z-[50]" />

            <div className="flex flex-col ms:flex-row items-start justify-start ">
              <div className="w-full">
                {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                  <p className=" text-base  text-primary font-semibold  transition duration-500  px-[35px] mb-1 ">
                    {Menubar_text.more.customer}
                  </p>
                )}
                <p className="  text-[#6e686e] font-semibold  transition duration-500  px-[35px] my-1  xxs:whitespace-nowrap">{` ${Menubar_text.more.welcome}  ${userDetails?.first_name} ${userDetails?.last_name}`}</p>
                {all_menus({
                  store_details: my_stores ? my_stores[0] : undefined,
                  TYPE_CONSTANT: TYPE_CONSTANT,
                  Menubar_text: Menubar_text,
                }).map((item) => {
                  if (item.is_show && item.type === 'customer') {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <Link href={item.endpoint}>
                            <a
                              className={
                                'flex items-center cursor-pointer w-full   px-[35px] py-2  hover:bg-[#f2f4f4] group gap-4'
                              }
                            >
                              <span className="text-[#6e686e] group-hover:text-primary">
                                {item.icon}
                              </span>
                              <span className=" text-sm  text-[#222222]  font-semibold  transition duration-500 group-hover:text-primary whitespace-nowrap">
                                {item.title}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    );
                  }
                })}

                <div className="hidden ms:block">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className="  flex items-center cursor-pointer w-auto   px-[35px] py-2  hover:bg-[#f2f4f4] group"
                        onClick={() => {
                          dispatch(logout({ router }));
                          window.location.reload();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 ltr:mr-3 rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5  text-[#6e686e]"
                          fill="none"
                          viewBox="0 0 24 24 "
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                        <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 group-hover:text-primary">
                          {Menubar_text.more.logging_out}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </div>

              {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
                my_stores?.length > 0 && (
                  <div className="w-full">
                    <p className=" text-base  text-primary font-semibold  transition duration-500  px-[35px] mb-1 mt-2 ms:mt-0">
                      {Menubar_text.more.business}
                    </p>
                    {all_menus({
                      store_details: my_stores ? my_stores[0] : undefined,
                      TYPE_CONSTANT: TYPE_CONSTANT,
                      Menubar_text: Menubar_text,
                    }).map((item) => {
                      if (item.is_show && item.type === 'business') {
                        return (
                          <Menu.Item>
                            {({ active }) => (
                              <Link href={item.endpoint}>
                                <a
                                  className={
                                    'flex items-center cursor-pointer w-full   px-[35px] py-2  hover:bg-[#f2f4f4] group gap-4'
                                  }
                                >
                                  <span className="text-[#6e686e] group-hover:text-primary">
                                    {item.icon}
                                  </span>
                                  <span className=" text-sm  text-[#222222]  font-semibold  transition duration-500 group-hover:text-primary whitespace-nowrap">
                                    {item.title}
                                  </span>
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      }
                    })}
                  </div>
                )}

              <div className="block ms:hidden ">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className="  flex items-center cursor-pointer w-auto   px-[35px] py-2  hover:bg-[#f2f4f4] group"
                      onClick={() => {
                        dispatch(logout({ router }));
                        window.location.reload();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ltr:mr-3 rtl:ml-3 ltr:sm:mr-5 rtl:sm:ml-5  text-[#6e686e]"
                        fill="none"
                        viewBox="0 0 24 24 "
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <span className=" text-sm  text-[#222222]  font-semibold transition duration-500 group-hover:text-primary">
                        {Menubar_text.more.logging_out}
                      </span>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
