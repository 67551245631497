import React from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const TagLine = ({}) => {
  const tagline = TYPE_CONSTANT.GENERAL_CONFIGS?.tagline;
  return (
    <p
      className=" w-full leading-none "
      style={{
        color:
          tagline?.text_color !== ''
            ? tagline?.text_color
            : 'var(--primary_color)',
        background: tagline?.background_color ?? 'transparent',
        fontSize: tagline?.font_size ?? '16px',
        fontWeight: tagline?.font_weight ?? 'normal',
        textAlign: tagline?.text_align ?? 'start',
        marginTop: tagline?.margin_top ?? '0px',
        minWidth: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
        // fontFamily: `${
        //   tagline?.font_family ?? 'var(--primary_font)'
        // } !important`,
      }}
    >
      {tagline?.text}
    </p>
  );
};

export default TagLine;
