import Link from 'next/link';
import React from 'react';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';

const SitemapLink = ({ data, styles }) => {
  return (
    <Link href={'/sitemap-index.xml'}>
      <a style={get_dynamic_styles(styles?.link)} target="_blank">
        {data?.title
          ? get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })
          : Menubar_text.more.sitemap}
      </a>
    </Link>
  );
};

export default SitemapLink;
