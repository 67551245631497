import React from 'react';
import Head from 'next/head';
import Header5 from '../../components/Header/Header5';
import Footer from '../../components/Footer/Footer';
import CustomFooter from '../../components/Footer/CustomFooter';
import Header6 from '../../components/Header/Header6';
import SimpleFooter from '../../components/Footer/SimpleFooter';

const MainLayout = ({ children, pageTitle, pageDescription }) => {
  return (
    <div>
      <div>
        <div>
          <Header6 />
        </div>
        <div
          className="  min-h-screen max-w-[1400px] mx-auto px-3 lg:px-9 mt-4 "
          id="template-components-body"
        >
          <div>{children}</div>
        </div>
        <div className="  bg-footer mt-16 md:mt-20   min-h-[200px]  left-0 bottom-0  ">
          <SimpleFooter />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
