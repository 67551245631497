import Link from 'next/link';
import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';

const FeaturedCard2 = ({ data, styles }) => {
  const { main_styles, box_styles, image_col_styles, desc_col_styles } =
    styles || {};

  return (
    <div
      className="flex items-center justify-center"
      style={get_dynamic_styles(main_styles)}
    >
      <div
        className="flex  flex-col  md:flex-row    overflow-hidden"
        style={get_dynamic_styles(box_styles)}
      >
        <div
          className={[
            ' flex flex-col gap-6',
            desc_col_styles?.is_show_in_first_column === false && 'order-last',
          ].join(' ')}
          style={get_dynamic_styles(desc_col_styles)}
        >
          <h2 className="heading-h2">
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'title',
              default_data: data?.title,
            })}
          </h2>
          <p className=" description-paragraph">
            {get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'description',
              default_data: data?.description,
            })}
          </p>
          {data?.link && data?.btn_title && (
            <div>
              <Link href={data?.link ?? '#'}>
                <a
                  className="btn-primary-fill w-auto cursor-pointer"
                  target={data?.link_opening ?? '_blank'}
                >
                  {get_data_by_current_language({
                    data_list: data?.translated_data,
                    key_name: 'btn_title',
                    default_data: data?.btn_title,
                  })}
                </a>
              </Link>
            </div>
          )}
        </div>
        <div className=" " style={get_dynamic_styles(image_col_styles)}>
          <img
            src={data?.image}
            className="w-full h-full object-cover rounded-image "
            alt="featured card"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FeaturedCard2);
