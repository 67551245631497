import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { getThumbnailImage } from '../Shared/Constant/Constant';
import HeaderNav from './HeaderNav';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const Header11 = () => {
  const [logo, setLogo] = useState(localStorage.getItem('logo'));
  const [login, setLogin] = useState(localStorage.getItem('login') ?? false);

  return (
    <div className=" mt-0 ">
      {/* logo */}
      <div className=" px-3 lg:px-9 py-3 flex items-center justify-between gap-3  ">
        <div className="flex-grow md:flex-none">
          <Link href="/">
            <div className="block">
              {logo && (
                <Link href="/" passHref={true}>
                  <a className=" flex items-center   relative cursor-pointer ">
                    <img
                      src={logo && getThumbnailImage(logo)}
                      style={{
                        height: `${
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height ?? 50
                        }px`,
                        width: `${TYPE_CONSTANT.GENERAL_CONFIGS?.logo_width}px`,
                        objectFit:
                          TYPE_CONSTANT.GENERAL_CONFIGS?.logo_object_fit ??
                          'contain',
                      }}
                      alt="logo"
                    />
                  </a>
                </Link>
              )}
            </div>
          </Link>
        </div>
        {/* <div className="hidden lg:block flex-grow   ">
          <SearchBox2 />
        </div> */}
        <div className="flex-none">
          <HeaderNav />
        </div>
      </div>
      <div className="  block mt-1 min-h-[12px]  relative after:absolute after:border-b after:border-[#22222226] after:left-0 after:right-0 after:bottom-0  after:-z-10">
        {/* <HeaderCategories2 /> */}
      </div>
      {/* <div className=" flex items-center justify-between px-3 lg:hidden gap-3 mb-3">
        <button className=" flex-none p-3 rounded-full  transition duration-400  hover:bg-[#22222213]  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </button>
        <div className=" flex-grow   ">
          <SearchBox2 />
        </div>
      </div> */}
    </div>
  );
};

export default Header11;
