import React from 'react';
import { Menubar_text } from '../../../constant/Translation/Heading';
import Link from 'next/link';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';

const ExternalLinks = ({ data, styles }) => {
  return (
    <div className="flex" style={get_dynamic_styles(styles?.box_styles)}>
      {data?.how_it_works_url && (
        <Link href={data?.how_it_works_url ?? '#'}>
          <a style={get_dynamic_styles(styles?.text)} target="_blank">
            {Menubar_text.more.how_it_work}
          </a>
        </Link>
      )}
      {data?.terms_url && (
        <Link href={data?.terms_url ?? '#'}>
          <a style={get_dynamic_styles(styles?.text)} target="_blank">
            {Menubar_text.more.terms_condition}
          </a>
        </Link>
      )}
      {data?.privacy_policy_url && (
        <Link href={data?.privacy_policy_url ?? '#'}>
          <a style={get_dynamic_styles(styles?.text)} target="_blank">
            {Menubar_text.more.privacy_policy}
          </a>
        </Link>
      )}
      {data?.faq_url && (
        <Link href={data?.faq_url ?? '#'}>
          <a style={get_dynamic_styles(styles?.text)} target="_blank">
            {Menubar_text.more.faq}
          </a>
        </Link>
      )}
      {data?.support_url && (
        <Link href={data?.support_url ?? '#'}>
          <a style={get_dynamic_styles(styles?.text)} target="_blank">
            {Menubar_text.more.support}
          </a>
        </Link>
      )}

      {data?.custom_footer_links &&
        data?.custom_footer_links?.length > 0 &&
        data?.custom_footer_links.map((link) => {
          return (
            <Link href={link.link ?? '#'}>
              <a style={get_dynamic_styles(styles?.text)} target="_blank">
                {get_data_by_current_language({
                  data_list: link?.translated_data,
                  key_name: 'title',
                  default_data: link?.title,
                })}
              </a>
            </Link>
          );
        })}
    </div>
  );
};

export default ExternalLinks;
